import { useState, useEffect, useRef } from 'react';
import { linearSearchPython, linearSearchJava, linearSearchC, linearSearchCpp } from './linearSearchAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useLinearSearch = (language, list, targetElement) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newSortedList, newTargetElement) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language == 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/list = \[.*?\]/, `list = [${newSortedList.join(", ")}]`)
          .replace(/target_element = \d+/, `target_element = ${newTargetElement}`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int list\[\] = \{.*?\};/,
            `int list[] = {${newSortedList.join(", ")}};`
          )
          .replace(/targetElement = \d+/, `targetElement = ${newTargetElement}`)
      );
    }
    
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
    await customSleep();
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runLinearSearchPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);
    
    await highlightLine(7);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(8);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'target_element', value: targetElement } }));
    setHighlightedVariables(['target_element']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(9);
    await customSleep();

    await highlightCallingLine(9);
    let result = await linearSearchPython(list, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(9);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    await customSleep();
    if (result !== -1) {
      await highlightLine(11);
      await logMessage(`Element ${targetElement} found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(12);
      await customSleep();

      await highlightLine(13);
      await logMessage(`Element ${targetElement} not found in the list.`);
      await customSleep();
    }

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runLinearSearchJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(11);
    await customSleep();

    await highlightLine(12);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(13);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'targetElement', value: targetElement } }));
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(14);
    await customSleep();

    await highlightCallingLine(14);
    let result = await linearSearchJava(list, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(14);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(15);
    await customSleep();
    if (result !== -1) {
      await highlightLine(16);
      await logMessage(`Element found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(18);
      await customSleep();

      await highlightLine(19);
      await logMessage(`Element not found`);
      await customSleep();
    }

    await focusEndLine(22);
    setIsRunning(false);
  };

  const runLinearSearchC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(12);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(13);
    let size = list.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(14);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'targetElement', value: targetElement } }));
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(15);
    await customSleep();

    await highlightCallingLine(15);
    let result = await linearSearchC(list, size, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(15);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(16);
    await customSleep();
    if (result !== -1) {
      await highlightLine(17);
      await logMessage(`Element found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(19);
      await customSleep();

      await highlightLine(20);
      await logMessage(`Element not found`);
      await customSleep();
    }

    await highlightLine(22);
    await customSleep();

    await focusEndLine(23);
    setIsRunning(false);
  };

  const runLinearSearchCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(13);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(14);
    let size = list.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(15);
    setVariables((vars) => ({ ...vars, targetElement: { variable_name: 'targetElement', value: targetElement } }));
    setHighlightedVariables(['targetElement']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(16);
    await customSleep();

    await highlightCallingLine(16);
    let result = await linearSearchCpp(list, size, targetElement, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, customSleep);
    setVariables((vars) => ({ ...vars, result: { variable_name: 'result', value: result } }));
    await highlightLine(16);
    setHighlightedVariables(['result']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(17);
    await customSleep();
    if (result !== -1) {
      await highlightLine(18);
      await logMessage(`Element found at index ${result}.`);
      await customSleep();
    } else {
      await highlightLine(20);
      await customSleep();

      await highlightLine(21);
      await logMessage(`Element not found`);
      await customSleep();
    }

    await highlightLine(23);
    await customSleep();

    await focusEndLine(24);
    setIsRunning(false);
  };

  const runLinearSearch = async () => {
    if (language === 'Python') {
      await runLinearSearchPython();
    } else if (language === 'Java') {
      await runLinearSearchJava();
    } else if (language === 'C') {
      await runLinearSearchC();
    } else if (language === 'C++') {
      await runLinearSearchCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runLinearSearch
  };
};
