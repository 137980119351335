import React, { useState, useEffect } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForLinkedList';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useLinkedListTraversal } from './useLinkedListTraversal';

const LinkedListTraversal = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([10, 20, 30]);
  const [dataInput, setDataInput] = useState("10, 20, 30");

  const [tempDataInput, setTempDataInput] = useState(dataInput);
  const [shouldUpdateVariables, setShouldUpdateVariables] = useState(false);

  const handleChangeTempData = (e) => {
    const input = e.target.value;
    setTempDataInput(input);
  };

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    nodeVariables,
    headVariables,
    highlightedVariables,
    highlightedHeadVariables,
    highlightedNodeVariables,
    highlightDataPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runLinkedList
  } = useLinkedListTraversal(data, language);

  const handleUpdateVariables = () => {
    const sanitizedInput = tempDataInput.trim().replace(/,+$/, "");
    
    setDataInput(sanitizedInput);
    const newArray = sanitizedInput
    .split(",")
    .map((item) => parseFloat(item) || 0)
    .slice(0, 3); // Limit to the first 4 elements if there are more

    // If fewer than 4 elements, pad with 0
    while (newArray.length < 3) {
      newArray.push(0);
    }

    setData(newArray);

    setShouldUpdateVariables(true);
    setModalVisible(false);
  };

  // React to `data` updates
  useEffect(() => {
    if (shouldUpdateVariables) {
      updateVariables();
      setShouldUpdateVariables(false);
    }
  }, [data]);

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Linked List Traversal"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setTempDataInput(data.join(", "));
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runLinkedList}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              nodeVariables={nodeVariables}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightDataPart={highlightDataPart}
              highlightNextAddressPart={highlightNextAddressPart}
              highlightNodeAddress={highlightNodeAddress}
              headVariables={headVariables}
              highlightedHeadVariables={highlightedHeadVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        dataInput={tempDataInput}
        onClose={() => setModalVisible(false)}
        onSave={handleUpdateVariables}
        onDataChange={handleChangeTempData}
      />
    </div>
  );
};

export default LinkedListTraversal;
