import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useEnumTypes = (language, initialEnum, input) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [highlightedMultipleLines, setHighlightedMultipleLines] = useState([]);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [enumVariables, setEnumVariables] = useState({});
  const [highlightedEnumVariables, setHighlightedEnumVariables] = useState([]);
  const [highlightedEnumIndex, setHighlightedEnumIndex] = useState([]);
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedStringIndices, setHighlightedStringIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);
  

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newInput) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'C' || language === 'C++') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/today\s*=\s*\w+/, `today = ${newInput}`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/input = ".*?"/, `input = "${newInput}"`)
      );
    }
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setHighlightedMultipleLines([]);
    setFocusedEndLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setEnumVariables({});
    setHighlightedEnumVariables([]);
    setHighlightedEnumIndex([]);
    setStringVariables({});
    setHighlightedStringVariables([]);
    setHighlightedStringIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightMultipleLines = async (lineNumbers) => {
    setHighlightedMultipleLines(lineNumbers);
    let lineNumber = lineNumbers[0];
    
    if (lineNumber) {
      await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
    }
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runEnumTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightMultipleLines([1, 2, 3]);
    setEnumVariables({ WorkDay: { variable_name: 'WorkDay', value: initialEnum } });
    setHighlightedEnumVariables(['WorkDay']);
    await customSleep();
    highlightMultipleLines([]);
    setHighlightedEnumVariables([]);

    
    await highlightLine(6);
    setStringVariables({ input: { variable_name: 'input', value: input } });
    setHighlightedStringVariables(['input']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(8);
    await customSleep();

    if (input in initialEnum) {
        await highlightLine(9);
        let today = {
            [input]: input
        }
        setEnumVariables((vars) => ({ ...vars, today: { variable_name: 'today', value: today } }));
        setHighlightedEnumVariables(['today']);
        await customSleep();
        highlightMultipleLines([]);
        setHighlightedEnumVariables([]);

        await highlightLine(10);
        setHighlightedEnumVariables(['today']);
        setHighlightedEnumIndex([{ enumName: 'WorkDay', key: 'FRIDAY' }]);
        await customSleep();
        setHighlightedEnumVariables([]);
        setHighlightedEnumIndex([]);
        if (today[input] === "FRIDAY") {
            await highlightLine(11);
            await logMessage("Thank God! It Is Friday.");
            await customSleep();
        } else {
            await highlightLine(12);
            await customSleep();

            await highlightLine(13);
            await logMessage("Time to work!");
            await customSleep();
        }
    } else {
        await highlightLine(15);
        await customSleep();

        await highlightLine(16);
        await logMessage("Invalid day entered. Please enter a valid day of the week.");
        await customSleep();
    }

    await focusEndLine(20);
    setIsRunning(false);
  };

  const runEnumTypesC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightMultipleLines([2, 3, 4]);
    setEnumVariables({ WorkDay: { variable_name: 'WorkDay', value: initialEnum } });
    setHighlightedEnumVariables(['WorkDay']);
    await customSleep();
    highlightMultipleLines([]);
    setHighlightedEnumVariables([]);

    await highlightLine(7);
    let today = {
        [input]: input
    }
    setEnumVariables((vars) => ({ ...vars, today: { variable_name: 'today', value: today } }));
    setHighlightedEnumVariables(['today']);
    await customSleep();
    highlightMultipleLines([]);
    setHighlightedEnumVariables([]);

    await highlightLine(9);
    setHighlightedEnumVariables(['today']);
    setHighlightedEnumIndex([{ enumName: 'WorkDay', key: 'FRIDAY' }]);
    await customSleep();
    setHighlightedEnumVariables([]);
    setHighlightedEnumIndex([]);
    if (today[input] === "FRIDAY") {
        await highlightLine(10);
        await logMessage("Thank God! It Is Friday.");
        await customSleep();
    } else {
        await highlightLine(11);
        await customSleep();

        await highlightLine(12);
        await logMessage("Time to work!");
        await customSleep();
    }

    await highlightLine(15);
    await customSleep();

    await focusEndLine(16);
    setIsRunning(false);
  };

  const runEnumTypesCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightMultipleLines([2, 3, 4]);
    setEnumVariables({ WorkDay: { variable_name: 'WorkDay', value: initialEnum } });
    setHighlightedEnumVariables(['WorkDay']);
    await customSleep();
    highlightMultipleLines([]);
    setHighlightedEnumVariables([]);

    await highlightLine(7);
    let today = {
        [input]: input
    }
    setEnumVariables((vars) => ({ ...vars, today: { variable_name: 'today', value: today } }));
    setHighlightedEnumVariables(['today']);
    await customSleep();
    highlightMultipleLines([]);
    setHighlightedEnumVariables([]);

    await highlightLine(9);
    setHighlightedEnumVariables(['today']);
    setHighlightedEnumIndex([{ enumName: 'WorkDay', key: 'FRIDAY' }]);
    await customSleep();
    setHighlightedEnumVariables([]);
    setHighlightedEnumIndex([]);
    if (today[input] === "FRIDAY") {
        await highlightLine(10);
        await logMessage("Thank God! It Is Friday.");
        await customSleep();
    } else {
        await highlightLine(11);
        await customSleep();

        await highlightLine(12);
        await logMessage("Time to work!");
        await customSleep();
    }

    await highlightLine(15);
    await customSleep();

    await focusEndLine(16);
    setIsRunning(false);
  };

  const runEnumTypes = async () => {
    if (language === 'Java') {
      await runEnumTypesJava();
    } else if (language === 'C') {
      await runEnumTypesC();
    } else if (language === 'C++') {
      await runEnumTypesCpp();
    }
  };

  return {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    enumVariables,
    stringVariables,
    highlightedVariables,
    highlightedEnumVariables,
    highlightedEnumIndex,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setHighlightedMultipleLines,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setEnumVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedEnumVariables,
    setHighlightedEnumIndex,
    setHighlightedStringVariables,
    setHighlightedStringIndices,
    runEnumTypes
  };
};
