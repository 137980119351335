import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useBasicArray = (language, array) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [arrayVariables, setArrayVariables] = useState({});
  const [variables, setVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newArray) => {
    resetState();

     // Dynamically update the code samples
     let updatedCodeSamples = "";
     if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/arr = \[.*?\]/, `arr = [${newArray.join(", ")}]`)
      );
     } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int arr\[\] = \{.*?\};/,
            `int arr[] = {${newArray.join(", ")}};`
          )
      );
     }
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setLogs([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
    setVariables({});
    setArrayVariables({});
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runBasicArrayPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedArrayVariables([]);

    
    for (let i = 0; i < array.length; i++) {
        await highlightLine(2);
        setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
        setHighlightedVariables(['i']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(3);
        setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
        await logMessage(`Element at index ${i}: ${array[i]}`);
        await customSleep();
        setHighlightedIndices([]);
    }

    await focusEndLine(3);
    setIsRunning(false);
  };

  const runBasicArrayJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedArrayVariables([]);

    
    for (let i = 0; i < array.length; i++) {
        await highlightLine(4);
        setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
        setHighlightedVariables(['i']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(5);
        setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
        await logMessage(`Element at index ${i}: ${array[i]}`);
        await customSleep();
        setHighlightedIndices([]);
    }

    await focusEndLine(8);
    setIsRunning(false);
  };

  const runBasicArrayC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(4);
    let size = array.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    
    for (let i = 0; i < array.length; i++) {
        await highlightLine(6);
        setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
        setHighlightedVariables(['i', 'size']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(7);
        setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
        await logMessage(`Element at index ${i}: ${array[i]}`);
        await customSleep();
        setHighlightedIndices([]);
    }

    await highlightLine(9);
    await customSleep();

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runBasicArrayCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(4);
    setArrayVariables({ arr: { variable_name: 'arr', value: array } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(5);
    let size = array.length;
    setVariables((vars) => ({ ...vars, size: { variable_name: 'size', value: size } }));
    setHighlightedVariables(['size']);
    await customSleep();
    setHighlightedVariables([]);

    
    for (let i = 0; i < array.length; i++) {
        await highlightLine(6);
        setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
        setHighlightedVariables(['i', 'size']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(7);
        setHighlightedIndices([{ arrayName: 'arr', index: i, iteratorName: 'i' }]);
        await logMessage(`Element at index ${i}: ${array[i]}`);
        await customSleep();
        setHighlightedIndices([]);
    }

    await highlightLine(9);
    await customSleep();

    await focusEndLine(10);
    setIsRunning(false);
  };

  const runBasicArray = async () => {
    if (language === 'Python') {
      await runBasicArrayPython();
    } else if (language === 'Java') {
      await runBasicArrayJava();
    } else if (language === 'C') {
      await runBasicArrayC();
    } else if (language === 'C++') {
      await runBasicArrayCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    arrayVariables,
    variables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setArrayVariables,
    setVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runBasicArray
  };
};
