import { useState, useEffect, useRef } from 'react';
import { 
  binarySearchTreePython,
  binarySearchTreeJava,
  binarySearchTreeCpp,
  binarySearchTreeC
} from './binarySearchTreeAlgorithms.js';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useBinarySearchTree = (language, data) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [secondCallingLine, setSecondCallingLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [nodeVariables, setNodeVariables] = useState({});
  const [singleNodeVariable, setSingleNodeVariable] = useState({});
  const [highlightSingleNodeDataPart, setHighlightSingleNodeDataPart] = useState([]);
  const [highlightSingleNodeLeftPart, setHighlightSingleNodeLeftPart] = useState([]);
  const [highlightSingleNodeRightPart, setHighlightSingleNodeRightPart] = useState([]);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedNodeVariables, setHighlightedNodeVariables] = useState([]);
  const [highlightedSingleNodeVariables, setHighlightedSingleNodeVariables] = useState([]);
  const [rootVariables, setRootVariables] = useState({});
  const [highlightedRootVariables, setHighlightedRootVariables] = useState([]);
  const [highlightDataPart, setHighlightDataPart] = useState([]);
  const [highlightLeftPart, setHighlightLeftPart] = useState([]);
  const [highlightRightPart, setHighlightRightPart] = useState([]);
  const [highlightNodeAddress, setHighlightNodeAddress] = useState([]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newData) => {
    resetState();
    
    let updatedCodeSamples = "";
    let index = 0;
  
    if (language === "C") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) => {
        // Match the pattern addNode(&root, number) and replace with updated values
        return line.replace(/addNode\(&root,\s*\d+\s*\)/g, () => {
          const newValue = newData[index] || 0; // Fallback to 0 if out of range
          index++;
          return `addNode(&root, ${newValue})`;
        });
      });
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) => {
        return line.replace(/add\s*\(\s*\d+\s*\)/g, () => {
          const newValue = newData[index] || 0; // Fallback to 0 if out of range
          index++;
          return `add(${newValue})`;
        });
      });
    }
  
    // Update the state
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setCallingLine(null);
    setSecondCallingLine(null);
    setVariables({});
    setRootVariables({});
    setNodeVariables({});
    setSingleNodeVariable({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedNodeVariables([]);
    setHighlightedSingleNodeVariables([]);
    setHighlightedRootVariables([]);
    setHighlightSingleNodeLeftPart([]);
    setHighlightSingleNodeDataPart([]);
    setHighlightSingleNodeRightPart([]);
    setHighlightLeftPart([]);
    setHighlightDataPart([]);
    setHighlightRightPart([]);
    setHighlightNodeAddress([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setSecondCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });

    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const highlightSecondCallingLine = async (lineNumber) => {
    setSecondCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (time=null) => {
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  // Run for Python
  const runBinaryTreePython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(30);
    await customSleep();

    await highlightCallingLine(30);
    await highlightLine(7);
    await customSleep();

    await highlightLine(8);
    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'None' }
    }));
    setHighlightedRootVariables(['root']);
    await customSleep();
    setHighlightedRootVariables([]);

    await highlightLine(30);
    await customSleep();

    await binarySearchTreePython(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables,
      setHighlightedVariables,
      setHighlightedRootVariables,
      setHighlightedNodeVariables,
      setHighlightedSingleNodeVariables,
      setHighlightSingleNodeLeftPart,
      setHighlightSingleNodeDataPart,
      setHighlightSingleNodeRightPart,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await focusEndLine(37);
    setIsRunning(false);
  };

  // Run for Java
  const runBinaryTreeJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(45);
    await customSleep();

    await highlightCallingLine(45);
    await highlightLine(13);
    await customSleep();

    await highlightLine(14);
    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'null' }
    }));
    setHighlightedRootVariables(['root']);
    await customSleep();
    setHighlightedRootVariables([]);

    await highlightLine(45);
    await customSleep();

    await binarySearchTreeJava(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables,
      setHighlightedVariables,
      setHighlightedRootVariables,
      setHighlightedNodeVariables,
      setHighlightedSingleNodeVariables,
      setHighlightSingleNodeLeftPart,
      setHighlightSingleNodeDataPart,
      setHighlightSingleNodeRightPart,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await focusEndLine(54);
    setIsRunning(false);
  };

  // Run for C
  const runBinaryTreeC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(43);
    await customSleep();

    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'NULL' }
    }));
    setHighlightedRootVariables(['root']);
    await customSleep();
    setHighlightedRootVariables([]);

    await binarySearchTreeC(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables,
      setHighlightedVariables,
      setHighlightedRootVariables,
      setHighlightedNodeVariables,
      setHighlightedSingleNodeVariables,
      setHighlightSingleNodeLeftPart,
      setHighlightSingleNodeDataPart,
      setHighlightSingleNodeRightPart,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await highlightLine(51);
    await customSleep();

    await focusEndLine(52);
    setIsRunning(false);
  };

  // Run for C++
  const runBinaryTreeCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(47);
    await customSleep();

    await highlightCallingLine(47);
    await highlightLine(16);
    await customSleep();

    await highlightLine(17);
    setRootVariables((vars) => ({ ...vars, 
      root: { variable_name: 'root', value: 'nullptr' }
    }));
    setHighlightedRootVariables(['root']);
    await customSleep();
    setHighlightedRootVariables([]);

    await highlightLine(47);
    await customSleep();

    await binarySearchTreeCpp(
      data,
      setNodeVariables,
      setSingleNodeVariable,
      setVariables,
      setRootVariables,
      setHighlightedVariables,
      setHighlightedRootVariables,
      setHighlightedNodeVariables,
      setHighlightedSingleNodeVariables,
      setHighlightSingleNodeLeftPart,
      setHighlightSingleNodeDataPart,
      setHighlightSingleNodeRightPart,
      setHighlightLeftPart,
      setHighlightDataPart,
      setHighlightRightPart,
      setHighlightNodeAddress,
      highlightLine,
      highlightCallingLine,
      highlightSecondCallingLine,
      customSleep
    );

    await highlightLine(55);
    await customSleep();

    await focusEndLine(56);
    setIsRunning(false);
  };

  // Main function to run based on language
  const runBinaryTree = async () => {
    if (language === 'Python') {
      await runBinaryTreePython();
    } else if (language === 'Java') {
      await runBinaryTreeJava();
    } else if (language === 'C') {
      await runBinaryTreeC();
    } else if (language === 'C++') {
      await runBinaryTreeCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    secondCallingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    rootVariables,
    nodeVariables,
    singleNodeVariable,
    highlightedVariables,
    highlightedRootVariables,
    highlightedNodeVariables,
    highlightedSingleNodeVariables,
    highlightSingleNodeDataPart,
    highlightSingleNodeLeftPart,
    highlightSingleNodeRightPart,
    highlightLeftPart,
    highlightDataPart,
    highlightRightPart,
    highlightNodeAddress,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setDelay,
    setIsPaused,
    setCallingLine,
    setSecondCallingLine,
    runBinaryTree,
  };
};
