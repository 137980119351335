import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useStack } from './useStack';

const Stack = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [stackSize, setStackSize] = useState(3);
  const [data, setData] = useState([10, 20, 30, 40]);
  const [dataInput, setDataInput] = useState("10, 20, 30, 40");

  const [tempDataInput, setTempDataInput] = useState(dataInput);

  const handleChangeTempData = (e) => {
    const input = e.target.value;
    setTempDataInput(input);
  };

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    stackVariables,
    tos,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedStackVariables,
    highlightedIndices,
    highlightedStackIndex,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runStack
  } = useStack(language, stackSize, data);

  const handleUpdateVariables = () => {
    const sanitizedInput = tempDataInput.trim().replace(/,+$/, "");
    
    setDataInput(sanitizedInput);

    const newArray = sanitizedInput
    .split(",")
    .map((item) => parseFloat(item) || 0)
    .slice(0, 4); // Limit to the first 4 elements if there are more

    // If fewer than 4 elements, pad with 0
    while (newArray.length < 4) {
      newArray.push(0);
    }

    setData(newArray);
    updateVariables(newArray);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Stack Implementation"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setTempDataInput(data.join(", "));
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runStack}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              arrayVariables={arrayVariables}
              variables={variables}
              stackVariables={stackVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
              highlightedStackVariables={highlightedStackVariables}
              highlightedIndices={highlightedIndices}
              highlightedStackIndex={highlightedStackIndex}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        dataInput={tempDataInput}
        handleData={handleChangeTempData}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default Stack;
