const explanations = {
    Python: {
        2: "Declares and initializes the string variable s1.",
        3: "Creates a new string by concatenating the existing string. The original string remains unchanged due to immutability, and s1 now references the new string."
    },
    Java: {
        3: "Declares and initializes the string variable s1.",
        4: "Creates a new string by concatenating the existing string. The original string remains unchanged due to immutability, and s1 now references the new string."
    },
  };
  
  export default explanations;
  