import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useBasicArray } from './useBasicArray';

const BasicArray = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [array, setArray] = useState([1, 2, 3, 4, 5]);
  const [arrayInput, setArrayInput] = useState("1, 2, 3, 4, 5");
  const [tempArrayInput, setTempArrayInput] = useState(arrayInput);

  const handleChangeTempArray = (e) => {
    const input = e.target.value;
    setTempArrayInput(input);
  };

  const {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    arrayVariables,
    variables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runBasicArray
  } = useBasicArray(language, array);


  const handleUpdateVariables = () => {
    const sanitizedInput = tempArrayInput.trim().replace(/,+$/, "");
    
    setArrayInput(sanitizedInput);
    const newArray = sanitizedInput.split(",").map((item) => parseFloat(item) || 0);
    setArray(newArray);
    updateVariables(newArray);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Basic Array"}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setTempArrayInput(array.join(", "));
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runBasicArray}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              arrayVariables={arrayVariables}
              variables={variables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
              highlightedIndices={highlightedIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        modalVisible={modalVisible}
        arrayInput={tempArrayInput}
        handleChange={handleChangeTempArray}
        onCancel={() => setModalVisible(false)}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default BasicArray;
