import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useTypeDef = (language, cakesBakedToday, cakesBakedYesterday) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newCakesBakedToday, newCakesBakedYesterday) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/cakesBakedToday = \d+/, `cakesBakedToday = ${newCakesBakedToday}`)
        .replace(/cakesBakedYesterday = \d+/, `cakesBakedYesterday = ${newCakesBakedYesterday}`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setCallingLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);    
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (time = null) => {
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runTypeDefC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, cakesBakedToday: { variable_name: 'cakesBakedToday', value: cakesBakedToday } }));
    setHighlightedVariables(['cakesBakedToday']);
    await logMessage(`Cakes baked today: ${cakesBakedToday}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setVariables((vars) => ({ ...vars, cakesBakedYesterday: { variable_name: 'cakesBakedYesterday', value: cakesBakedYesterday } }));
    setHighlightedVariables(['cakesBakedYesterday']);
    await logMessage(`Cakes baked today: ${cakesBakedYesterday}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['cakesBakedToday']);
    await logMessage(`Cakes baked today: ${cakesBakedToday}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['cakesBakedYesterday']);
    await logMessage(`Cakes baked today: ${cakesBakedYesterday}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    await customSleep();

    await focusEndLine(12);
    setIsRunning(false);
  };

  const runTypeDefCpp = async () => {
    await runTypeDefC(); // Since the logic is similar for C and C++
  };

  const runTypeDef = async () => {
    if (language === 'C') {
      await runTypeDefC();
    } else if (language === 'C++') {
      await runTypeDefCpp();
    }
  };

  return {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    highlightedVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setCallingLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setHighlightedVariables,
    runTypeDef,
  };
};
