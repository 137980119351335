import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useArithmeticOperations = (language, x, y) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const isMutedRef = useRef(isMuted);

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newX, newY) => {
    resetState();

     // Dynamically update the code samples
     const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/x = \d+/, `x = ${newX}`) // Replace variable 'a'
        .replace(/y = \d+/, `y = ${newY}`) // Replace variable 'b'
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runArithmeticOperationsPython = async () => {
    await highlightLine(1);
    setVariables((vars) => ({ ...vars, x: { variable_name: 'x', value: x } }));
    setHighlightedVariables(['x']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, y: { variable_name: 'y', value: y } }));
    setHighlightedVariables(['y']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, addition: { variable_name: 'addition', value: x + y } }));
    setHighlightedVariables(['addition']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    await logMessage(`Addition: ${x} + ${y} = ${x + y}`);
    await customSleep();

    await highlightLine(7);
    setVariables((vars) => ({ ...vars, subtraction: { variable_name: 'subtraction', value: x - y } }));
    setHighlightedVariables(['subtraction']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    await logMessage(`Subtraction: ${x} - ${y} = ${x - y}`);
    await customSleep();

    await highlightLine(10);
    setVariables((vars) => ({ ...vars, multiplication: { variable_name: 'multiplication', value: x * y } }));
    setHighlightedVariables(['multiplication']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    await logMessage(`Multiplication: ${x} * ${y} = ${x * y}`);
    await customSleep();

    await highlightLine(13);
    const divisionResult = y !== 0 ? x / y : "Error: Division by zero is undefined.";
    setVariables((vars) => ({ ...vars, division: { variable_name: 'division', value: divisionResult } }));
    setHighlightedVariables(['division']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(14);
    await logMessage(`Division: ${x} / ${y} = ${divisionResult}`);
    await customSleep();

    await highlightLine(16);
    setVariables((vars) => ({ ...vars, modulus: { variable_name: 'modulus', value: x % y } }));
    setHighlightedVariables(['modulus']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(17);
    await logMessage(`Modulus: ${x} % ${y} = ${x % y}`);
    await customSleep();

    await highlightLine(19);
    setVariables((vars) => ({ ...vars, exponentiation: { variable_name: 'exponentiation', value: x ** y } }));
    setHighlightedVariables(['exponentiation']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(20);
    await logMessage(`Exponentiation: ${x} ** ${y} = ${x ** y}`);
    await customSleep();

    await highlightLine(22);
    const floorDivisionResult = y !== 0 ? Math.floor(x / y) : "Error: Division by zero is undefined.";
    setVariables((vars) => ({ ...vars, floor_division: { variable_name: 'floor_division', value: floorDivisionResult } }));
    setHighlightedVariables(['floor_division']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(23);
    await logMessage(`Floor Division: ${x} // ${y} = ${floorDivisionResult}`);
    await customSleep();

    await focusEndLine(23);
    setIsRunning(false);
  };

  const runArithmeticOperationsJava = async () => {
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, x: { variable_name: 'x', value: x } }));
    setHighlightedVariables(['x']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, y: { variable_name: 'y', value: y } }));
    setHighlightedVariables(['y']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, addition: { variable_name: 'addition', value: x + y } }));
    setHighlightedVariables(['addition']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    await logMessage(`Addition: ${x + y}`);
    await customSleep();

    await highlightLine(8);
    setVariables((vars) => ({ ...vars, subtraction: { variable_name: 'subtraction', value: x - y } }));
    setHighlightedVariables(['subtraction']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(9);
    await logMessage(`Subtraction: ${x - y}`);
    await customSleep();

    await highlightLine(11);
    setVariables((vars) => ({ ...vars, multiplication: { variable_name: 'multiplication', value: x * y } }));
    setHighlightedVariables(['multiplication']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(12);
    await logMessage(`Multiplication: ${x * y}`);
    await customSleep();

    await highlightLine(14);
    const divisionResult = y !== 0 ? (x / y).toFixed(2) : "Error: Division by zero is undefined.";
    setVariables((vars) => ({ ...vars, division: { variable_name: 'division', value: divisionResult } }));
    setHighlightedVariables(['division']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(15);
    await logMessage(`Division: ${divisionResult}`);
    await customSleep();

    await highlightLine(17);
    setVariables((vars) => ({ ...vars, modulus: { variable_name: 'modulus', value: x % y } }));
    setHighlightedVariables(['modulus']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(18);
    await logMessage(`Modulus: ${x % y}`);
    await customSleep();

    await highlightLine(20);
    const exponentiationResult = Math.pow(x, y);
    setVariables((vars) => ({ ...vars, exponentiation: { variable_name: 'exponentiation', value: exponentiationResult } }));
    setHighlightedVariables(['exponentiation']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(21);
    await logMessage(`Exponentiation: ${exponentiationResult}`);
    await customSleep();

    await highlightLine(23);
    const floorDivisionResult = y !== 0 ? Math.floor(x / y) : "Error: Division by zero is undefined.";
    setVariables((vars) => ({ ...vars, floor_division: { variable_name: 'floorDivision', value: floorDivisionResult } }));
    setHighlightedVariables(['floorDivision']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(24);
    await logMessage(`Floor Division: ${floorDivisionResult}`);
    await customSleep();

    await focusEndLine(26);
    setIsRunning(false);
  };

  const runArithmeticOperationsC = async () => {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, x: { variable_name: 'x', value: x } }));
    setHighlightedVariables(['x']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, y: { variable_name: 'y', value: y } }));
    setHighlightedVariables(['y']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    setVariables((vars) => ({ ...vars, addition: { variable_name: 'addition', value: x + y } }));
    setHighlightedVariables(['addition']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    await logMessage(`Addition: ${x + y}`);
    await customSleep();

    await highlightLine(10);
    setVariables((vars) => ({ ...vars, subtraction: { variable_name: 'subtraction', value: x - y } }));
    setHighlightedVariables(['subtraction']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    await logMessage(`Subtraction: ${x - y}`);
    await customSleep();

    await highlightLine(13);
    setVariables((vars) => ({ ...vars, multiplication: { variable_name: 'multiplication', value: x * y } }));
    setHighlightedVariables(['multiplication']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(14);
    await logMessage(`Multiplication: ${x * y}`);
    await customSleep();

    await highlightLine(16);
    setVariables((vars) => ({ ...vars, division: { variable_name: 'division', value: '' } }));
    setHighlightedVariables(['division']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(17);
    setHighlightedVariables(['y']);
    await customSleep();
    setHighlightedVariables([]);
    if (y !== 0) {
        await highlightLine(18);
        const divisionResult = (x / y).toFixed(2);
        setVariables((vars) => ({ ...vars, division: { variable_name: 'division', value: divisionResult } }));
        setHighlightedVariables(['division']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(19);
        await logMessage(`Division: ${divisionResult}`);
        await customSleep();
    } else {
        await highlightLine(20);
        setHighlightedVariables(['y']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(21);
        await logMessage(`Err: Division by zero is undefined.`);
        await customSleep();
    }

    await highlightLine(24);
    setVariables((vars) => ({ ...vars, modulus: { variable_name: 'modulus', value: x % y } }));
    setHighlightedVariables(['modulus']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(25);
    await logMessage(`Modulus: ${x % y}`);
    await customSleep();

    await highlightLine(27);
    const exponentiationResult = Math.pow(x, y);
    setVariables((vars) => ({ ...vars, exponentiation: { variable_name: 'exponentiation', value: exponentiationResult } }));
    setHighlightedVariables(['exponentiation']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(28);
    await logMessage(`Exponentiation: ${exponentiationResult}`);
    await customSleep();

    await highlightLine(30);
    setVariables((vars) => ({ ...vars, floorDivision: { variable_name: 'floorDivision', value: '' } }));
    setHighlightedVariables(['floorDivision']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(31);
    setHighlightedVariables(['y']);
    await customSleep();
    setHighlightedVariables([]);
    if (y !== 0) {
        await highlightLine(32);
        const floorDivisionResult = y !== 0 ? Math.floor(x / y) : "Error: Division by zero is undefined.";
        setVariables((vars) => ({ ...vars, floorDivision: { variable_name: 'floorDivision', value: floorDivisionResult } }));
        setHighlightedVariables(['floorDivision']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(33);
        await logMessage(`Floor Division: ${floorDivisionResult}`);
        await customSleep();
    } else {
        await highlightLine(34);
        setHighlightedVariables(['y']);
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(35);
        await logMessage(`Err: Division by zero is undefined.`);
        await customSleep();
    }

    await highlightLine(38);
    await customSleep();

    await focusEndLine(39);
    setIsRunning(false);
  };

  const runArithmeticOperationsCpp = async () => {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, x: { variable_name: 'x', value: x } }));
    setHighlightedVariables(['x']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, y: { variable_name: 'y', value: y } }));
    setHighlightedVariables(['y']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    setVariables((vars) => ({ ...vars, addition: { variable_name: 'addition', value: x + y } }));
    setHighlightedVariables(['addition']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    await logMessage(`Addition: ${x + y}`);
    await customSleep();

    await highlightLine(10);
    setVariables((vars) => ({ ...vars, subtraction: { variable_name: 'subtraction', value: x - y } }));
    setHighlightedVariables(['subtraction']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    await logMessage(`Subtraction: ${x - y}`);
    await customSleep();

    await highlightLine(13);
    setVariables((vars) => ({ ...vars, multiplication: { variable_name: 'multiplication', value: x * y } }));
    setHighlightedVariables(['multiplication']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(14);
    await logMessage(`Multiplication: ${x * y}`);
    await customSleep();

    await highlightLine(16);
    const divisionResult = y !== 0 ? (x / y).toFixed(2) : "Error: Division by zero is undefined.";
    setVariables((vars) => ({ ...vars, division: { variable_name: 'division', value: divisionResult } }));
    setHighlightedVariables(['division']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(17);
    await logMessage(`Division: ${divisionResult}`);
    await customSleep();

    await highlightLine(19);
    setVariables((vars) => ({ ...vars, modulus: { variable_name: 'modulus', value: x % y } }));
    setHighlightedVariables(['modulus']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(20);
    await logMessage(`Modulus: ${x % y}`);
    await customSleep();

    await highlightLine(22);
    const exponentiationResult = Math.pow(x, y);
    setVariables((vars) => ({ ...vars, exponentiation: { variable_name: 'exponentiation', value: exponentiationResult } }));
    setHighlightedVariables(['exponentiation']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(23);
    await logMessage(`Exponentiation: ${exponentiationResult}`);
    await customSleep();

    await highlightLine(25);
    const floorDivisionResult = y !== 0 ? Math.floor(x / y) : "Error: Division by zero is undefined.";
    setVariables((vars) => ({ ...vars, floor_division: { variable_name: 'floorDivision', value: floorDivisionResult } }));
    setHighlightedVariables(['floorDivision']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(26);
    await logMessage(`Floor Division: ${floorDivisionResult}`);
    await customSleep();

    await highlightLine(28);
    await customSleep();

    await focusEndLine(30);
    setIsRunning(false);
  };

  const runArithmeticOperations = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    if (language === 'Python') {
      await runArithmeticOperationsPython();
    } else if (language === 'Java') {
      await runArithmeticOperationsJava();
    } else if (language === 'C') {
      await runArithmeticOperationsC();
    } else if (language === 'C++') {
      await runArithmeticOperationsCpp();
    }

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    runArithmeticOperations
  };
};
