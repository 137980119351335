import React, { useState, useEffect } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForBinaryTree';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useBinaryTree } from './useBinaryTree';

const BinaryTreePreorderTraversal = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState([20, 15, 30, 10, 17, 25, 40]);
  const [dataInput, setDataInput] = useState("20, 15, 30, 10, 17, 25, 40");
  const [tempDataInput, setTempDataInput] = useState(dataInput);

  const handleTempDataChange = (e) => {
    setTempDataInput(e.target.value);
  };

  const {
    highlightedLine,
    callingLine,
    secondCallingLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    rootVariables,
    nodeVariables,
    singleNodeVariable,
    stackVariables,
    highlightedVariables,
    highlightedRootVariables,
    highlightedNodeVariables,
    highlightedSingleNodeVariables,
    highlightSingleNodeLeftPart,
    highlightSingleNodeDataPart,
    highlightSingleNodeRightPart,
    highlightLeftPart,
    highlightDataPart,
    highlightRightPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    highlightedStackVariables,
    highlightedStackIndex,
    strikeThroughStackIndex,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runBinaryTree,
  } = useBinaryTree(language, data);

  
  const handleUpdateVariables = () => {
    setDataInput(tempDataInput);
    const newArray = tempDataInput.split(",").map((item) => parseFloat(item) || 0);

    // Adjust the array length to 7 by filling with zeros
    while (newArray.length < 7) {
      newArray.push(0);
    }
    setData(newArray);
    setTempDataInput(newArray.join(", "));
    setModalVisible(false);
  };

  // React to `data` updates
  useEffect(() => {
    updateVariables();
  }, [data]);

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={"Preorder Traversal"}
            callingLine={callingLine}
            secondCallingLine={secondCallingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => setModalVisible(true)}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runBinaryTree}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              rootVariables={rootVariables}
              highlightedRootVariables={highlightedRootVariables}
              singleNodeVariable={singleNodeVariable}
              nodeVariables={nodeVariables}
              highlightedNodeVariables={highlightedNodeVariables}
              highlightedSingleNodeVariables={highlightedSingleNodeVariables}
              highlightSingleNodeLeftPart={highlightSingleNodeLeftPart}
              highlightSingleNodeDataPart={highlightSingleNodeDataPart}
              highlightSingleNodeRightPart={highlightSingleNodeRightPart}
              highlightLeftPart={highlightLeftPart}
              highlightDataPart={highlightDataPart}
              highlightRightPart={highlightRightPart}
              highlightNextAddressPart={highlightNextAddressPart}
              highlightNodeAddress={highlightNodeAddress}
              stackVariables={stackVariables}
              highlightedStackVariables={highlightedStackVariables}
              highlightedStackIndex={highlightedStackIndex}
              strikeThroughStackIndex={strikeThroughStackIndex}
              language={language}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => {
          setTempDataInput(data.join(", "));
          setModalVisible(false);
        }}
        dataInput={tempDataInput}
        handleData={handleTempDataChange}
        handleUpdateVariables={handleUpdateVariables}
      />
    </div>
  );
};

export default BinaryTreePreorderTraversal;
