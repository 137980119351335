import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useStringInterning } from './useStringInterning';

const StringInterning = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [s1, setS1] = useState("hello");
  const [s2, setS2] = useState("world");
  const [s3, setS3] = useState("hello");

  const [inputS1, setInputS1] = useState(s1);
  const [inputS2, setInputS2] = useState(s2);
  const [inputS3, setInputS3] = useState(s3);

  const {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    stringVariables,
    highlightedVariables,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runStringInterning,
  } = useStringInterning(language, s1, s2, s3);


  const handleUpdateVariables = () => {
    let sanitizedStr1 = (inputS1 || s1).slice(0, 15);
    let sanitizedStr2 = (inputS2 || s2).slice(0, 15);
    let sanitizedStr3 = (inputS3 || s3).slice(0, 15);
    
    setS1(sanitizedStr1);
    setS2(sanitizedStr2);
    setS3(sanitizedStr3);

    updateVariables(sanitizedStr1, sanitizedStr2, sanitizedStr3);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"String Interning"}
            callingLine={callingLine}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setInputS1(s1);
              setInputS2(s2);
              setInputS3(s3);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runStringInterning}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              stringVariables={stringVariables}
              highlightedVariables={highlightedVariables}
              highlightedStringVariables={highlightedStringVariables}
              highlightedStringIndices={highlightedStringIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        s1={inputS1}
        s2={inputS2}
        s3={inputS3}
        setS1={setInputS1}
        setS2={setInputS2}
        setS3={setInputS3}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default StringInterning;
