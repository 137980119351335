import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useSwitchCaseOperations = (language, day) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newDay) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/day = \d+/, `day = ${newDay}`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runSwitchCaseOperationsJava = async () => {
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, day: { variable_name: 'day', value: day } }));
    setHighlightedVariables(['day']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    let dayName = ['null'];
    setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName } }));
    setHighlightedArrayVariables(['dayName'])
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedArrayVariables([])

    await highlightLine(6);
    setHighlightedVariables(['day']);
    await customSleep();
    switch (day) {
      case 1:
        await highlightLine(7);
        await customSleep();

        await highlightLine(8);
        dayName = ['S', 'u', 'n', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(9);
        await customSleep();

        break;
      case 2:
        await highlightLine(7);
        await customSleep();

        await highlightLine(10);
        await customSleep();

        await highlightLine(11);
        dayName = ['M', 'o', 'n', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(12);
        await customSleep();

        break;
      case 3:
        await highlightLine(7);
        await customSleep();
        
        await highlightLine(10);
        await customSleep();

        await highlightLine(13);
        await customSleep();

        await highlightLine(14);
        dayName = ['T', 'u', 'e', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([])

        await highlightLine(15);
        await customSleep();

        break;
      case 4:
        await highlightLine(7);
        await customSleep();
        
        await highlightLine(10);
        await customSleep();

        await highlightLine(13);
        await customSleep();

        await highlightLine(16);
        await customSleep();

        await highlightLine(17);
        dayName = ['W', 'e', 'd', 'n', 'e', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(18);
        await customSleep();
        
        break;
      case 5:
        await highlightLine(7);
        await customSleep();
        
        await highlightLine(10);
        await customSleep();

        await highlightLine(13);
        await customSleep();

        await highlightLine(16);
        await customSleep();

        await highlightLine(19);
        await customSleep();

        await highlightLine(20);
        dayName = ['T', 'h', 'u', 'r', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(21);
        await customSleep();

        break;
      case 6:
        await highlightLine(7);
        await customSleep();
        
        await highlightLine(10);
        await customSleep();

        await highlightLine(13);
        await customSleep();

        await highlightLine(16);
        await customSleep();

        await highlightLine(19);
        await customSleep();

        await highlightLine(22);
        await customSleep();

        await highlightLine(23);
        dayName = ['F', 'r', 'i', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(24);
        await customSleep();

        break;
      case 7:
        await highlightLine(7);
        await customSleep();
        
        await highlightLine(10);
        await customSleep();

        await highlightLine(13);
        await customSleep();

        await highlightLine(16);
        await customSleep();

        await highlightLine(19);
        await customSleep();

        await highlightLine(22);
        await customSleep();

        await highlightLine(25);
        await customSleep();

        await highlightLine(26);
        dayName = ['S', 'a', 't', 'u', 'r', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(27);
        await customSleep();

        break;
      default:
        await highlightLine(7);
        await customSleep();
        
        await highlightLine(10);
        await customSleep();

        await highlightLine(13);
        await customSleep();

        await highlightLine(16);
        await customSleep();

        await highlightLine(19);
        await customSleep();

        await highlightLine(22);
        await customSleep();

        await highlightLine(25);
        await customSleep();

        await highlightLine(28);
        await customSleep();

        await highlightLine(29);
        dayName = ['I', 'n', 'v', 'a', 'l', 'i', 'd', '', 'd' ,'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '25px' } }));
        setHighlightedArrayVariables(['dayName'])
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(30);
        await customSleep();

        break;
    }
    setHighlightedVariables([]);

    await highlightLine(33);
    await logMessage(`The day is: ${dayName}`);
    await customSleep();

    await focusEndLine(35);
    setIsRunning(false);
  };

  const runSwitchCaseOperationsC = async () => {
    await highlightLine(3);
    setVariables((vars) => ({ ...vars, day: { variable_name: 'day', value: day } }));
    setHighlightedVariables(['day']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    let dayName = ['null'];
    setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName } }));
    setHighlightedArrayVariables(['dayName'])
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedArrayVariables([])

    await highlightLine(7);
    setHighlightedVariables(['day']);
    await customSleep();
    switch (day) {
      case 1:
        await highlightLine(8);
        await customSleep();

        await highlightLine(9);
        dayName = ['S', 'u', 'n', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(10);
        await customSleep();

        break;
      case 2:
        await highlightLine(8);
        await customSleep();

        await highlightLine(11);
        await customSleep();

        await highlightLine(12);
        dayName = ['M', 'o', 'n', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(13);
        await customSleep();

        break;
      case 3:
        await highlightLine(8);
        await customSleep();
        
        await highlightLine(11);
        await customSleep();

        await highlightLine(14);
        await customSleep();

        await highlightLine(15);
        dayName = ['T', 'u', 'e', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([])

        await highlightLine(16);
        await customSleep();

        break;
      case 4:
        await highlightLine(8);
        await customSleep();
        
        await highlightLine(11);
        await customSleep();

        await highlightLine(14);
        await customSleep();

        await highlightLine(17);
        await customSleep();

        await highlightLine(18);
        dayName = ['W', 'e', 'd', 'n', 'e', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(19);
        await customSleep();
        
        break;
      case 5:
        await highlightLine(8);
        await customSleep();
        
        await highlightLine(11);
        await customSleep();

        await highlightLine(14);
        await customSleep();

        await highlightLine(17);
        await customSleep();

        await highlightLine(20);
        await customSleep();

        await highlightLine(21);
        dayName = ['T', 'h', 'u', 'r', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(22);
        await customSleep();

        break;
      case 6:
        await highlightLine(8);
        await customSleep();
        
        await highlightLine(11);
        await customSleep();

        await highlightLine(14);
        await customSleep();

        await highlightLine(17);
        await customSleep();

        await highlightLine(20);
        await customSleep();

        await highlightLine(23);
        await customSleep();

        await highlightLine(24);
        dayName = ['F', 'r', 'i', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(25);
        await customSleep();

        break;
      case 7:
        await highlightLine(8);
        await customSleep();
        
        await highlightLine(11);
        await customSleep();

        await highlightLine(14);
        await customSleep();

        await highlightLine(17);
        await customSleep();

        await highlightLine(20);
        await customSleep();

        await highlightLine(23);
        await customSleep();

        await highlightLine(26);
        await customSleep();

        await highlightLine(27);
        dayName = ['S', 'a', 't', 'u', 'r', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(28);
        await customSleep();

        break;
      default:
        await highlightLine(8);
        await customSleep();
        
        await highlightLine(11);
        await customSleep();

        await highlightLine(14);
        await customSleep();

        await highlightLine(17);
        await customSleep();

        await highlightLine(20);
        await customSleep();

        await highlightLine(23);
        await customSleep();

        await highlightLine(26);
        await customSleep();

        await highlightLine(29);
        await customSleep();

        await highlightLine(30);
        dayName = ['I', 'n', 'v', 'a', 'l', 'i', 'd', '', 'd' ,'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '25px' } }));
        setHighlightedArrayVariables(['dayName'])
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(31);
        await customSleep();

        break;
    }
    setHighlightedVariables([]);

    await highlightLine(34);
    await logMessage(`The day is: ${dayName}`);
    await customSleep();

    await highlightLine(35);
    await customSleep();

    await focusEndLine(36);
    setIsRunning(false);
  };

  const runSwitchCaseOperationsCpp = async () => {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, day: { variable_name: 'day', value: day } }));
    setHighlightedVariables(['day']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    let dayName = ['null'];
    setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName } }));
    setHighlightedArrayVariables(['dayName'])
    await customSleep();
    setHighlightedVariables([]);
    setHighlightedArrayVariables([])

    await highlightLine(8);
    setHighlightedVariables(['day']);
    await customSleep();
    switch (day) {
      case 1:
        await highlightLine(9);
        await customSleep();

        await highlightLine(10);
        dayName = ['S', 'u', 'n', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(11);
        await customSleep();

        break;
      case 2:
        await highlightLine(9);
        await customSleep();

        await highlightLine(12);
        await customSleep();

        await highlightLine(13);
        dayName = ['M', 'o', 'n', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(14);
        await customSleep();

        break;
      case 3:
        await highlightLine(9);
        await customSleep();
        
        await highlightLine(12);
        await customSleep();

        await highlightLine(15);
        await customSleep();

        await highlightLine(16);
        dayName = ['T', 'u', 'e', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([])

        await highlightLine(17);
        await customSleep();

        break;
      case 4:
        await highlightLine(9);
        await customSleep();
        
        await highlightLine(12);
        await customSleep();

        await highlightLine(15);
        await customSleep();

        await highlightLine(18);
        await customSleep();

        await highlightLine(19);
        dayName = ['W', 'e', 'd', 'n', 'e', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(20);
        await customSleep();
        
        break;
      case 5:
        await highlightLine(9);
        await customSleep();
        
        await highlightLine(12);
        await customSleep();

        await highlightLine(15);
        await customSleep();

        await highlightLine(18);
        await customSleep();

        await highlightLine(21);
        await customSleep();

        await highlightLine(22);
        dayName = ['T', 'h', 'u', 'r', 's', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(23);
        await customSleep();

        break;
      case 6:
        await highlightLine(9);
        await customSleep();
        
        await highlightLine(12);
        await customSleep();

        await highlightLine(15);
        await customSleep();

        await highlightLine(18);
        await customSleep();

        await highlightLine(21);
        await customSleep();

        await highlightLine(24);
        await customSleep();

        await highlightLine(25);
        dayName = ['F', 'r', 'i', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(26);
        await customSleep();

        break;
      case 7:
        await highlightLine(9);
        await customSleep();
        
        await highlightLine(12);
        await customSleep();

        await highlightLine(15);
        await customSleep();

        await highlightLine(18);
        await customSleep();

        await highlightLine(21);
        await customSleep();

        await highlightLine(24);
        await customSleep();

        await highlightLine(27);
        await customSleep();

        await highlightLine(28);
        dayName = ['S', 'a', 't', 'u', 'r', 'd', 'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '30px' } }));
        setHighlightedArrayVariables(['dayName']);
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(29);
        await customSleep();

        break;
      default:
        await highlightLine(9);
        await customSleep();
        
        await highlightLine(12);
        await customSleep();

        await highlightLine(15);
        await customSleep();

        await highlightLine(18);
        await customSleep();

        await highlightLine(21);
        await customSleep();

        await highlightLine(24);
        await customSleep();

        await highlightLine(27);
        await customSleep();

        await highlightLine(30);
        await customSleep();

        await highlightLine(31);
        dayName = ['I', 'n', 'v', 'a', 'l', 'i', 'd', '', 'd' ,'a', 'y'];
        setArrayVariables((vars) => ({ ...vars, dayName: { variable_name: "dayName", value: dayName, width: '25px' } }));
        setHighlightedArrayVariables(['dayName'])
        await customSleep();
        setHighlightedArrayVariables([]);

        await highlightLine(32);
        await customSleep();

        break;
    }
    setHighlightedVariables([]);

    await highlightLine(35);
    await logMessage(`The day is: ${dayName}`);
    await customSleep();

    await highlightLine(36);
    await customSleep();

    await focusEndLine(37);
    setIsRunning(false);
  };

  const runSwitchCaseOperations = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    if (language === 'Java') {
      await runSwitchCaseOperationsJava();
    } else if (language === 'C') {
      await runSwitchCaseOperationsC();
    } else if (language === 'C++') {
      await runSwitchCaseOperationsCpp();
    }

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runSwitchCaseOperations
  };
};
