import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useEnumTypes } from './useEnumTypes';

const EnumTypes = ({ language, topic, subTopicId }) => {
  const initialEnum = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY'
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [input, setInput] = useState("THURSDAY");
  const [tempInput, setTempInput] = useState(input);

  const {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    enumVariables,
    stringVariables,
    highlightedVariables,
    highlightedEnumVariables,
    highlightedEnumIndex,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runEnumTypes
  } = useEnumTypes(language, initialEnum, input);

  const handleUpdateVariables = () => {
    setInput(tempInput);
    updateVariables(tempInput);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={`${topic} Data Types`}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setTempInput(input);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runEnumTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              enumVariables={enumVariables}
              highlightedEnumVariables={highlightedEnumVariables}
              highlightedEnumIndex={highlightedEnumIndex}
              stringVariables={stringVariables}
              highlightedStringVariables={highlightedStringVariables}
              highlightedStringIndices={highlightedStringIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        input={tempInput}
        setInput={setTempInput}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default EnumTypes;
