const explanations = {
  Python: {
    2: "Defining the list of numbers",
    4: "Looping through the numbers",
    5: "Checking if the number is divisible by both 3 and 5",
    6: "Printing 'FizzBuzz'",
    7: "Checking if the number is divisible by 3",
    8: "Printing 'Fizz'",
    9: "Checking if the number is divisible by 5",
    10: "Printing 'Buzz'",
    11: "None of the conditions match",
    12: "Printing the number"
  },
  Java: {
    3: "Defining the array of numbers",
    5: "Looping through the numbers",
    6: "Checking if the number is divisible by both 3 and 5",
    7: "Printing 'FizzBuzz'",
    8: "Checking if the number is divisible by 3",
    9: "Printing 'Fizz'",
    10: "Checking if the number is divisible by 5",
    11: "Printing 'Buzz'",
    12: "None of the conditions match",
    13: "Printing the number"
  },
  C: {
    3: "Defining the array of numbers",
    4: "Calculate the size of the array",
    6: "Looping through the numbers",
    7: "Checking if the number is divisible by both 3 and 5",
    8: "Printing 'FizzBuzz'",
    9: "Checking if the number is divisible by 3",
    10: "Printing 'Fizz'",
    11: "Checking if the number is divisible by 5",
    12: "Printing 'Buzz'",
    13: "None of the conditions match",
    14: "Printing the number",
    17: "Returning 0 to indicate successful execution"
  },
  "C++": {
    4: "Defining the array of numbers",
    5: "Calculate the size of the array",
    7: "Looping through the numbers",
    8: "Checking if the number is divisible by both 3 and 5",
    9: "Printing 'FizzBuzz'",
    10: "Checking if the number is divisible by 3",
    11: "Printing 'Fizz'",
    12: "Checking if the number is divisible by 5",
    13: "Printing 'Buzz'",
    14: "None of the conditions match",
    15: "Printing the number",
    18: "Returning 0 to indicate successful execution"
  }
};

export default explanations;
