import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useSequenceTypes } from './useSequenceTypes';

const SequenceTypes = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [name, setName] = useState("Alice");
  const [fruits, setFruits] = useState(["apple", "banana", "cherry"]);
  const [fruitsInput, setFruitsInput] = useState("apple, banana, cherry");
  const [coordinates, setCoordinates] = useState([10, 20, 30]);
  const [coordinatesInput, setCoordinatesInput] = useState("10, 20, 30");

  const [tempName, setTempName] = useState(name);
  const [tempFruitsInput, setTempFruitsInput] = useState(fruitsInput);
  const [tempCoordinatesInput, setTempCoordinatesInput] = useState(coordinatesInput);

  const numbers = Array.from({ length: 5 }, (_, i) => i + 1);

  const handleChangeTempFruitsInput = (e) => {
    const input = e.target.value;
    setTempFruitsInput(input);
  };

  const handleChangeTempCoordinatesInput = (e) => {
    const input = e.target.value;
    setTempCoordinatesInput(input);
  };

  const {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runSequenceTypes
  } = useSequenceTypes(language, name, fruits, coordinates, numbers);

  const handleUpdateVariables = () => {
    const sanitizedFruitsInput = tempFruitsInput
      .split(",")
      .map(fruit => fruit.trim())
      .join(",")
      .replace(/,+$/, "");
    setFruitsInput(sanitizedFruitsInput);
    const newFruitsArray = sanitizedFruitsInput.split(",");
    setFruits(newFruitsArray);

    const sanitizedCoordinatesInput = tempCoordinatesInput.trim().replace(/,+$/, "");
    setCoordinatesInput(sanitizedCoordinatesInput);
    const newCoordinatesArray = sanitizedCoordinatesInput.split(",").map((item) => parseFloat(item) || 0);
    setCoordinates(newCoordinatesArray);

    setName(tempName);
    updateVariables(tempName, newFruitsArray, newCoordinatesArray);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Sequence Types in Python"}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setTempName(name);
              setTempFruitsInput(fruits.slice(0, 3).join(", "));
              setTempCoordinatesInput(coordinates.join(", "));
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runSequenceTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedArrayVariables={highlightedArrayVariables}
              highlightedIndices={highlightedIndices}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        name={tempName}
        fruitsInput={tempFruitsInput}
        coordinatesInput={tempCoordinatesInput}
        handleFruitChange={handleChangeTempFruitsInput}
        handleCoordinatesChange={handleChangeTempCoordinatesInput}
        setName={setTempName}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default SequenceTypes;
