import { useState, useEffect, useRef } from 'react';
import { queuePython, queueJava, queueC, queueCpp } from './queueAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useQueue = (language, queueSize, data) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [stackVariables, setStackVariables] = useState({});
  const [front, setFront] = useState(-1);
  const [rear, setRear] = useState(-1);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedStackVariables, setHighlightedStackVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [highlightedStackIndex, setHighlightedStackIndex] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newData) => {
    resetState();

    let updatedCodeSamples = "";
    let index = 0;
    // Dynamically update the code samples
    updatedCodeSamples = codeSamplesTemplate[language].map((line) => {
      return line.replace(/enqueue\s*\(\s*\d+\s*\)/g, () => {
        const newValue = newData[index] || 0; // Fallback to 0 if out of range
        index++;
        return `enqueue(${newValue})`;
      });
    });
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setFront(-1);
    setRear(-1);
    setVariables({});
    setArrayVariables({});
    setStackVariables({});
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedStackVariables([]);
    setHighlightedIndices([]);
    setHighlightedStackIndex([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runQueuePython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, enqueue, dequeue, display } = await queuePython(
      highlightLine,
      setVariables,
      setArrayVariables,
      setStackVariables,
      setHighlightedVariables,
      setHighlightedArrayVariables,
      setHighlightedStackVariables,
      setHighlightedIndices,
      setHighlightedStackIndex,
      highlightCallingLine,
      customSleep,
      logMessage
    );

    await highlightLine(32);
    await customSleep();

    await highlightCallingLine(32);
    await init(queueSize);
    await highlightLine(32);
    await customSleep();

    await highlightLine(34);
    await customSleep();

    await highlightCallingLine(34);
    await dequeue();
    await customSleep();
    await highlightLine(34);
    await customSleep();

    await highlightLine(36);
    await customSleep();

    await highlightCallingLine(36);
    await enqueue(data[0]);
    await customSleep();
    await highlightLine(36);
    await customSleep();

    await highlightLine(37);
    await customSleep();

    await highlightCallingLine(37);
    await enqueue(data[1]);
    await customSleep();
    await highlightLine(37);
    await customSleep();

    await highlightLine(38);
    await customSleep();

    await highlightCallingLine(38);
    await enqueue(data[2]);
    await customSleep();
    await highlightLine(38);
    await customSleep();

    await highlightLine(39);
    await customSleep();

    await highlightCallingLine(39);
    await enqueue(data[3]);
    await customSleep();
    await highlightLine(39);
    await customSleep();

    await highlightLine(41);
    await customSleep();

    await highlightCallingLine(41);
    await display();
    await customSleep();
    await highlightLine(41);
    await customSleep();

    await highlightLine(43);
    await customSleep();

    await highlightCallingLine(43);
    await dequeue();
    await highlightLine(43);
    await customSleep();

    await highlightLine(45);
    await customSleep();

    await highlightCallingLine(45);
    await display();
    await customSleep();
    await highlightLine(45);
    await customSleep();

    await focusEndLine(45);
    setIsRunning(false);
  };

  const runQueueJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, enqueue, dequeue, display } = await queueJava(
      highlightLine,
      setVariables,
      setArrayVariables,
      setStackVariables,
      setHighlightedVariables,
      setHighlightedArrayVariables,
      setHighlightedStackVariables,
      setHighlightedIndices,
      setHighlightedStackIndex,
      highlightCallingLine,
      customSleep,
      logMessage
    );

    await highlightLine(45);
    await customSleep();

    await highlightCallingLine(45);
    await init(queueSize);
    await customSleep();
    await highlightLine(45);
    await customSleep();

    await highlightLine(46);
    await customSleep();

    await highlightCallingLine(46);
    await dequeue();
    await customSleep();
    await highlightLine(46);
    await customSleep();

    await highlightLine(48);
    await customSleep();

    await highlightCallingLine(48);
    await enqueue(data[0]);
    await customSleep();
    await highlightLine(48);
    await customSleep();

    await highlightLine(49);
    await customSleep();

    await highlightCallingLine(49);
    await enqueue(data[1]);
    await customSleep();
    await highlightLine(49);
    await customSleep();

    await highlightLine(50);
    await customSleep();

    await highlightCallingLine(50);
    await enqueue(data[2]);
    await customSleep();
    await highlightLine(50);
    await customSleep();

    await highlightLine(51);
    await customSleep();

    await highlightCallingLine(51);
    await enqueue(data[3]);
    await customSleep();
    await highlightLine(51);
    await customSleep();

    await highlightLine(53);
    await customSleep();

    await highlightCallingLine(53);
    await display();
    await customSleep();
    await highlightLine(53);
    await customSleep();

    await highlightLine(55);
    await customSleep();

    await highlightCallingLine(55);
    await dequeue();
    await highlightLine(55);
    await customSleep();

    await highlightLine(57);
    await customSleep();

    await highlightCallingLine(57);
    await display();
    await customSleep();
    await highlightLine(57);
    await customSleep();

    await focusEndLine(59);
    setIsRunning(false);
  };

  const runQueueC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, enqueue, dequeue, display } = await queueC(
      highlightLine,
      setVariables,
      setArrayVariables,
      setStackVariables,
      setHighlightedVariables,
      setHighlightedArrayVariables,
      setHighlightedStackVariables,
      setHighlightedIndices,
      setHighlightedStackIndex,
      highlightCallingLine,
      customSleep,
      logMessage
    );

    await highlightLine(40);
    await customSleep();
    
    await highlightCallingLine(40);
    await init(queueSize);
    await highlightLine(40);
    await customSleep();

    await highlightLine(41);
    await customSleep();

    await highlightCallingLine(41);
    await dequeue();
    await customSleep();
    await highlightLine(41);
    await customSleep();

    await highlightLine(43);
    await customSleep();

    await highlightCallingLine(43);
    await enqueue(data[0]);
    await customSleep();
    await highlightLine(43);
    await customSleep();

    await highlightLine(44);
    await customSleep();

    await highlightCallingLine(44);
    await enqueue(data[1]);
    await customSleep();
    await highlightLine(44);
    await customSleep();

    await highlightLine(45);
    await customSleep();

    await highlightCallingLine(45);
    await enqueue(data[2]);
    await customSleep();
    await highlightLine(44);
    await customSleep();

    await highlightLine(46);
    await customSleep();

    await highlightCallingLine(46);
    await enqueue(data[3]);
    await customSleep();
    await highlightLine(46);
    await customSleep();

    await highlightLine(48);
    await customSleep();

    await highlightCallingLine(48);
    await display();
    await customSleep();
    await highlightLine(48);
    await customSleep();

    await highlightLine(50);
    await customSleep();

    await highlightCallingLine(50);
    await dequeue();
    await highlightLine(50);
    await customSleep();

    await highlightLine(52);
    await customSleep();

    await highlightCallingLine(52);
    await display();
    await customSleep();
    await highlightLine(52);
    await customSleep();

    await highlightLine(54);
    await customSleep();

    await focusEndLine(55);
    setIsRunning(false);
  };

  const runQueueCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    const { init, enqueue, dequeue, display } = await queueCpp(
      highlightLine,
      setVariables,
      setArrayVariables,
      setStackVariables,
      setHighlightedVariables,
      setHighlightedArrayVariables,
      setHighlightedStackVariables,
      setHighlightedIndices,
      setHighlightedStackIndex,
      highlightCallingLine,
      customSleep,
      logMessage
    );

    await highlightLine(41);
    await customSleep();
    
    await highlightCallingLine(41);
    await init(queueSize);
    await highlightLine(41);
    await customSleep();

    await highlightLine(42);
    await customSleep();

    await highlightCallingLine(42);
    await dequeue();
    await customSleep();
    await highlightLine(42);
    await customSleep();

    await highlightLine(44);
    await customSleep();

    await highlightCallingLine(44);
    await enqueue(data[0]);
    await customSleep();
    await highlightLine(44);
    await customSleep();

    await highlightLine(45);
    await customSleep();

    await highlightCallingLine(45);
    await enqueue(data[1]);
    await customSleep();
    await highlightLine(45);
    await customSleep();

    await highlightLine(46);
    await customSleep();

    await highlightCallingLine(46);
    await enqueue(data[2]);
    await customSleep();
    await highlightLine(46);
    await customSleep();

    await highlightLine(47);
    await customSleep();

    await highlightCallingLine(47);
    await enqueue(data[3]);
    await customSleep();
    await highlightLine(47);
    await customSleep();

    await highlightLine(49);
    await customSleep();

    await highlightCallingLine(49);
    await display();
    await customSleep();
    await highlightLine(49);
    await customSleep();

    await highlightLine(51);
    await customSleep();

    await highlightCallingLine(51);
    await dequeue();
    await highlightLine(51);
    await customSleep();

    await highlightLine(53);
    await customSleep();

    await highlightCallingLine(53);
    await display();
    await customSleep();
    await highlightLine(53);
    await customSleep();

    await highlightLine(55);
    await customSleep();

    await focusEndLine(56);
    setIsRunning(false);
  };

  const runQueue = async () => {
    if (language === 'Python') {
      await runQueuePython();
    } else if (language === 'Java') {
      await runQueueJava();
    } else if (language === 'C') {
      await runQueueC();
    } else if (language === 'C++') {
      await runQueueCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    stackVariables,
    front,
    rear,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedStackVariables,
    highlightedIndices,
    highlightedStackIndex,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setStackVariables,
    setFront,
    setRear,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedStackVariables,
    setHighlightedIndices,
    setHighlightedStackIndex,
    runQueue
  };
};
