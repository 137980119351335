import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useBasicCode } from './useBasicCode';

const BasicCode = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [a, setA] = useState(10);
  const [b, setB] = useState(15);

  const [inputA, setInputA] = useState(a);
  const [inputB, setInputB] = useState(b);

  const {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runBasicCode
  } = useBasicCode(language, a, b);


  const handleUpdateVariables = () => {
    setA(inputA);
    setB(inputB);
    updateVariables(inputA, inputB);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={"Basic Code Execution"}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setInputA(a);
              setInputB(b);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runBasicCode}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        modalVisible={modalVisible}
        variables={{ inputA, inputB }}
        setVariables={{ setInputA, setInputB }}
        onCancel={() => setModalVisible(false)}
        onSave={handleUpdateVariables}
      />
    </div>
  );
};

export default BasicCode;
