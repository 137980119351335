import { useState, useEffect, useRef } from 'react';
import { dogConstructorForJava, dogSoundFunctionForJava } from './classFunctions';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useInterfaceType = (language, initialInterface, initialClass) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [classVariables, setClassVariables] = useState({});
  const [highlightedClassVariables, setHighlightedClassVariables] = useState([]);
  const [highlightedClassMethods, setHighlightedClassMethods] = useState([]);
  const [interfaceVariables, setInterfaceVariables] = useState({});
  const [highlightedInterfaceVariables, setHighlightedInterfaceVariables] = useState([]);
  const [highlightedInterfaceMethods, setHighlightedInterfaceMethods] = useState([]);
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newInitialClass) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/favoriteToy = ".*?"/, `favoriteToy = "${newInitialClass?.favoriteToy}"`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setCallingLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setClassVariables({});
    setHighlightedClassVariables([]);
    setHighlightedClassMethods([]);
    setInterfaceVariables({});
    setHighlightedInterfaceVariables([]);
    setHighlightedInterfaceMethods([]);
    setStringVariables({});
    setHighlightedStringVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);    
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (time = null) => {
    let start = Date.now();
    let ms;
    if (time === null) {
      ms = delayRef.current;
    } else {
      ms = time;
    }

    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runInterfaceTypeJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(18);
    let interfaceMethod = [
      {
        name: 'sound',
        type: 'abstract',
        parameters: [],
        returnType: 'void'
      }
    ];

    setInterfaceVariables((vars) => ({ ...vars, Animal: { 
      interfaceName: 'Animal',
      value: {methods: interfaceMethod} 
    } }));
    setHighlightedClassVariables(['Animal']);
    await customSleep();
    setHighlightedClassVariables([]);

    let classAttributes = [
      {
        name: 'favoriteToy',
        dataType: 'String'
      }
    ];

    let classMethods = [
      {
        name: 'Dog',
        type: 'constructor',
        parameters: [],
        returnType: null
      },
      {
        name: 'sound',
        type: 'member',
        parameters: [],
        returnType: 'void'
      }
    ];

    setClassVariables((vars) => ({ ...vars, myDog: { 
      objectName: 'myDog',
      className: 'Dog',
      value: {attributes: classAttributes, methods: classMethods} 
    } }));
    setHighlightedClassVariables(['myDog']);
    await customSleep();
    setHighlightedClassVariables([]);

    await highlightCallingLine(18);
    setHighlightedClassMethods(['Dog']);
    await dogConstructorForJava(
      highlightLine,
      setStringVariables,
      setHighlightedStringVariables,
      customSleep,
      initialClass
    )
    setHighlightedClassMethods([]);
    await highlightLine(18);
    await customSleep();

    await highlightLine(19);
    await customSleep();

    await highlightCallingLine(19);
    setHighlightedInterfaceMethods(['sound']);
    setHighlightedClassMethods(['sound']);
    await dogSoundFunctionForJava(
      highlightLine,
      setHighlightedStringVariables,
      customSleep,
      logMessage,
      initialClass
    )
    setHighlightedClassMethods([]);
    setHighlightedInterfaceMethods([]);
    await highlightLine(19);
    await customSleep();

    await focusEndLine(21);
    setIsRunning(false);
  };

  const runInterfaceType = async () => {
    if (language === 'Java') {
      await runInterfaceTypeJava();
    }
  };

  return {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    highlightedVariables,
    classVariables,
    highlightedClassVariables,
    highlightedClassMethods,
    interfaceVariables,
    highlightedInterfaceVariables,
    highlightedInterfaceMethods,
    stringVariables,
    highlightedStringVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setCallingLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setHighlightedVariables,
    setClassVariables,
    setHighlightedClassVariables,
    setHighlightedClassMethods,
    setInterfaceVariables,
    setHighlightedInterfaceVariables,
    setHighlightedInterfaceMethods,
    setStringVariables,
    setHighlightedStringVariables,
    runInterfaceType
  };
};
