import { useEffect, useRef } from 'react';

export const useSpeechSynthesis = () => {
  const synth = window.speechSynthesis;
  const isMutedRef = useRef(false);

  const speak = (message) => {
    return new Promise((resolve) => {
      if (isMutedRef.current) {
        resolve();
        return;
      }
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = resolve;
      synth.speak(utterance);

      // Periodically check for mute
      const checkMuteInterval = setInterval(() => {
        if (isMutedRef.current) {
          synth.cancel();
          clearInterval(checkMuteInterval);
          resolve();
        }
      }, 50);
    });
  };

  useEffect(() => {
    const handleUnload = () => synth.cancel();
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      synth.cancel();
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [synth]);

  const setIsMuted = (isMuted) => {
    isMutedRef.current = isMuted;
  };

  return { speak, setIsMuted };
};
