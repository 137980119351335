import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useSetTypes } from './useSetTypes';

const SetTypes = ({ language, subTopicId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [fruits, setFruits] = useState(["Apple", "RaspBerries", "Apple"]);
  const [fruitsInput, setFruitsInput] = useState("Apple, RaspBerries, Apple");
  const initialFruits = new Set(fruits);

  const [addFruit, setAddFruit] = useState("Orange");
  const [removeFruit, setRemoveFruit] = useState("Apple");

  const [berryFruits, setBerryFruits] = useState(["BlueBerries", "RaspBerries"]);
  const [berryFruitsInput, setBerryFruitsInput] = useState("BlueBerries, RaspBerries");

  const [tempFruitsInput, setTempFruitsInput] = useState(fruitsInput);
  const [tempAddFruit, setTempAddFruit] = useState(addFruit);
  const [tempRemoveFruit, setTempRemoveFruit] = useState(removeFruit);
  const [tempBerryFruitsInput, setTempBerryFruitsInput] = useState(berryFruitsInput);

  const handleChangeTempFruitsInput = (e) => {
    const input = e.target.value;
    setTempFruitsInput(input);
  };

  const handleChangeTempBerryFruitsInput = (e) => {
    const input = e.target.value;
    setTempBerryFruitsInput(input);
  };

  const {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    setsVariables,
    highlightedVariables,
    highlightedSetVariables,
    highlightedSetIndex,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runSetTypes
  } = useSetTypes(language, fruits, initialFruits, addFruit, removeFruit, berryFruits);

  const handleUpdateVariables = () => {
    const sanitizedFruitsInput = tempFruitsInput
      .split(",")
      .map(fruit => fruit.trim())
      .join(",")
      .replace(/,+$/, "");
    setFruitsInput(sanitizedFruitsInput);
    const newFruitsArray = sanitizedFruitsInput.split(",");
    setFruits(newFruitsArray);

    const sanitizedBerryFruitsInput = tempBerryFruitsInput
      .split(",")
      .map(fruit => fruit.trim())
      .join(",")
      .replace(/,+$/, "");
    setBerryFruitsInput(sanitizedBerryFruitsInput);
    const newBerryFruitsArray = sanitizedBerryFruitsInput.split(",");
    setBerryFruits(newBerryFruitsArray);

    setAddFruit(tempAddFruit);
    setRemoveFruit(tempRemoveFruit);

    updateVariables(newFruitsArray, tempAddFruit, tempRemoveFruit, newBerryFruitsArray);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={"Set Data Types"}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setTempFruitsInput(fruits.join(", "));
              setTempAddFruit(addFruit);
              setTempRemoveFruit(removeFruit);
              setTempBerryFruitsInput(berryFruits.join(", "));
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runSetTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              setsVariables={setsVariables}
              highlightedVariables={highlightedVariables}
              highlightedSetVariables={highlightedSetVariables}
              highlightedSetIndex={highlightedSetIndex}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        fruitsInput={tempFruitsInput}
        handleFruitChange={handleChangeTempFruitsInput}
        addFruit={tempAddFruit}
        setAddFruit={setTempAddFruit}
        removeFruit={tempRemoveFruit}
        setRemoveFruit={setTempRemoveFruit}
        berryFruitsInput={tempBerryFruitsInput}
        handleBerryFruitChange={handleChangeTempBerryFruitsInput}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default SetTypes;
