import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvas';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useDictionaryTypes } from './useDictionaryTypes';

const DictionaryTypes = ({ language, topic, subTopicId }) => {
  const name = "Alice";
  const age = 22;
  const graduated = language === 'Python' ? 'False' : 'false';

  const [modalVisible, setModalVisible] = useState(false);
  const [initialStudent, setInitialStudent] = useState({ name: name, age: age, graduated: graduated });

  const [inputInitialStudent, setInputInitialStudent] = useState({ name: name, age: age, graduated: graduated });

  const {
    highlightedLine,
    highlightedMultipleLines,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    mapVariables,
    highlightedVariables,
    highlightedMapVariables,
    highlightedMapIndex,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runDictionaryTypes
  } = useDictionaryTypes(language, initialStudent);

  const handleUpdateVariables = () => {
    setInitialStudent(inputInitialStudent);
    updateVariables(inputInitialStudent);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            highlightedLine={highlightedLine}
            highlightedMultipleLines={highlightedMultipleLines}
            focusedEndLine={focusedEndLine}
            topic={`${topic} Data Types`}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setInputInitialStudent(initialStudent);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runDictionaryTypes}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              arrayVariables={arrayVariables}
              highlightedVariables={highlightedVariables}
              highlightedMapVariables={highlightedMapVariables}
              mapVariables={mapVariables}
              highlightedMapIndex={highlightedMapIndex}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
        <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        initialStudent={inputInitialStudent}
        setInitialStudent={setInputInitialStudent}
        onSave={handleUpdateVariables}
        language={language}
      />
    </div>
  );
};

export default DictionaryTypes;
