import { useState, useEffect, useRef } from 'react';
import { selectionSortPython, selectionSortJava, selectionSortC, selectionSortCpp } from './selectionSortAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useSelectionSort = (language, list) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [minIndex, setMinIndex] = useState(null);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newList) => {
    resetState();

    // Dynamically update the code samples
    let updatedCodeSamples = "";
    if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/list = \[.*?\]/, `list = [${newList.join(", ")}]`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int list\[\] = \{.*?\};/,
            `int list[] = {${newList.join(", ")}};`
          )
      );
    }
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setMinIndex(null);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
    await customSleep();
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runSelectionSortPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(10);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(11);
    await customSleep();

    await highlightCallingLine(11);
    let sortedList = await selectionSortPython(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep);
    setArrayVariables((vars) => ({ ...vars, sorted_list: { variable_name: 'sorted_list', value: sortedList } }));
    setHighlightedArrayVariables(['sorted_list']);
    await highlightLine(11);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(12);
    await logMessage(`Sorted list: ${sortedList}`);
    await customSleep();

    await focusEndLine(12);
    setIsRunning(false);
  };

  const runSelectionSortJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(15);
    await customSleep();

    await highlightLine(16);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(17);
    await customSleep();

    await highlightCallingLine(17);
    let sortedList = await selectionSortJava(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedList: { variable_name: 'sortedList', value: sortedList } }));
    setHighlightedArrayVariables(['sortedList']);
    await highlightLine(17);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(18);
    await logMessage(`Sorted list: ${sortedList}`);
    await customSleep();

    await focusEndLine(20);
    setIsRunning(false);
  };

  const runSelectionSortC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(17);
    let n = list.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(18);
    await customSleep();

    await highlightCallingLine(18);
    let sortedList = await selectionSortC(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedList: { variable_name: 'sortedList', value: sortedList } }));
    setHighlightedArrayVariables(['sortedList']);
    await highlightLine(18);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(19);
    await logMessage(`Sorted list: `);
    await customSleep();

    for(let itr = 0; itr < n; itr++) {
      setHighlightedIndices([{ arrayName: 'sortedList', index: itr, iteratorName: 'itr' }]);

      await highlightLine(20);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr']);
      await customSleep();
      setHighlightedVariables([]);
      
      await highlightLine(21);
      await logMessage(`${sortedList[itr]}`);
      await customSleep();
    }
    setHighlightedIndices([]);

    await highlightLine(23);
    await customSleep();

    await highlightLine(24);
    await customSleep();

    await focusEndLine(25);
    setIsRunning(false);
  };

  const runSelectionSortCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(16);
    setArrayVariables({ list: { variable_name: 'list', value: list } });
    setHighlightedArrayVariables(['list']);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(17);
    let n = list.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(18);
    await customSleep();

    await highlightCallingLine(18);
    let sortedList = await selectionSortCpp(list, highlightLine, setVariables, setHighlightedVariables, setHighlightedIndices, setMinIndex, customSleep);
    setArrayVariables((vars) => ({ ...vars, sortedList: { variable_name: 'sortedList', value: sortedList } }));
    setHighlightedArrayVariables(['sortedList']);
    await highlightLine(18);
    await customSleep();
    setHighlightedArrayVariables([]);

    await highlightLine(19);
    await logMessage(`Sorted list: ${sortedList}`);
    await customSleep();

    await highlightLine(20);
    await customSleep();

    await focusEndLine(21);
    setIsRunning(false);
  };

  const runSelectionSort = async () => {
    if (language === 'Python') {
      await runSelectionSortPython();
    } else if (language === 'Java') {
      await runSelectionSortJava();
    } else if (language === 'C') {
      await runSelectionSortC();
    } else if (language === 'C++') {
      await runSelectionSortCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runSelectionSort
  };
};
