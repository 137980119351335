import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useIfElseOperations = (language, number) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newumber) => {
    resetState();

    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/number = \d+/, `number = ${newumber}`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runIfElseOperationsPython = async () => {
    await highlightLine(1);
    setVariables((vars) => ({ ...vars, number: { variable_name: 'number', value: number } }));
    setHighlightedVariables(['number']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setHighlightedVariables(['number']);
    await customSleep();
    if (number > 0) {
        await highlightLine(4);
        await logMessage("The number is positive.");
        await customSleep();
    } else if (number < 0) {
        await highlightLine(5);
        await customSleep();

        await highlightLine(6);
        await logMessage("The number is negative.");
        await customSleep();
    } else {
        await highlightLine(5);
        await customSleep();

        await highlightLine(7);
        await customSleep();

        await highlightLine(8);
        await logMessage("The number is zero.");
        await customSleep();
    }
    setHighlightedVariables([]);

    await focusEndLine(8);
    setIsRunning(false);
  };

  const runIfElseOperationsJava = async () => {
    await highlightLine(2);
    setVariables((vars) => ({ ...vars, number: { variable_name: 'number', value: number } }));
    setHighlightedVariables(['number']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setHighlightedVariables(['number']);
    await customSleep();
    if (number > 0) {
        await highlightLine(5);
        await logMessage("The number is positive.");
        await customSleep();
    } else if (number < 0) {
        await highlightLine(6);
        await customSleep();

        await highlightLine(7);
        await logMessage("The number is negative.");
        await customSleep();
    } else {
        await highlightLine(6);
        await customSleep();

        await highlightLine(8);
        await customSleep();

        await highlightLine(9);
        await logMessage("The number is zero.");
        await customSleep();
    }
    setHighlightedVariables([]);

    await focusEndLine(12);
    setIsRunning(false);
  };

  const runIfElseOperationsC = async () => {
    await highlightLine(3);
    setVariables((vars) => ({ ...vars, number: { variable_name: 'number', value: number } }));
    setHighlightedVariables(['number']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setHighlightedVariables(['number']);
    await customSleep();
    if (number > 0) {
        await highlightLine(6);
        await logMessage("The number is positive.");
        await customSleep();
    } else if (number < 0) {
        await highlightLine(7);
        await customSleep();

        await highlightLine(8);
        await logMessage("The number is negative.");
        await customSleep();
    } else {
        await highlightLine(7);
        await customSleep();

        await highlightLine(9);
        await customSleep();

        await highlightLine(10);
        await logMessage("The number is zero.");
        await customSleep();
    }
    setHighlightedVariables([]);

    await highlightLine(12);
    await customSleep();

    await focusEndLine(13);
    setIsRunning(false);
  };

  const runIfElseOperationsCpp = async () => {
    await highlightLine(4);
    setVariables((vars) => ({ ...vars, number: { variable_name: 'number', value: number } }));
    setHighlightedVariables(['number']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['number']);
    await customSleep();
    if (number > 0) {
        await highlightLine(7);
        await logMessage("The number is positive.");
        await customSleep();
    } else if (number < 0) {
        await highlightLine(8);
        await customSleep();

        await highlightLine(9);
        await logMessage("The number is negative.");
        await customSleep();
    } else {
        await highlightLine(8);
        await customSleep();

        await highlightLine(10);
        await customSleep();

        await highlightLine(11);
        await logMessage("The number is zero.");
        await customSleep();
    }
    setHighlightedVariables([]);

    await highlightLine(13);
    await customSleep();

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runIfElseOperations = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    if (language === 'Python') {
      await runIfElseOperationsPython();
    } else if (language === 'Java') {
      await runIfElseOperationsJava();
    } else if (language === 'C') {
      await runIfElseOperationsC();
    } else if (language === 'C++') {
      await runIfElseOperationsCpp();
    }

    setIsRunning(false);
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    runIfElseOperations
  };
};
