import React, { useState } from 'react';
import CodeDisplay from '../CodeDisplay';
import Controls from '../Controls';
import VisualizationCanvas from '../VisualizationCanvasForUnion';
import AlgorithmDescription from '../AlgorithmDescription';
import LoggerDrawer from '../LoggerDrawer';
import UpdateVariablesModal from './UpdateVariablesModal';

import { useUnionType } from './useUnionType';

const UnionType = ({ language, subTopicId }) => {
  const initialUnion = {
    number: null,
    letter: null,
    candy: null,
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [number, setNumber] = useState(7);
  const [letter, setLetter] = useState('A');
  const [candy, setCandy] = useState("Lollipop");

  const [inputNumber, setInputNumber] = useState(number);
  const [inputLetter, setInputLetter] = useState(letter);
  const [inputCandy, setInputCandy] = useState(candy);

  const {
    highlightedLine,
    callingLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    unionVariables,
    stringVariables,
    highlightedVariables,
    highlightedUnionVariables,
    highlightedStringVariables,
    isMuted,
    codeSamples,
    setDelay,
    setIsPaused,
    setIsMuted,
    updateVariables,
    runUnionType
  } = useUnionType(language, initialUnion, number, letter, candy);

  const handleUpdateVariables = () => {
    setNumber(inputNumber);
    setLetter(inputLetter);
    setCandy(inputCandy);
    updateVariables(inputNumber, inputLetter, inputCandy);
    setModalVisible(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="content-area d-flex">
        <div className="col-lg-6">
          <CodeDisplay
            codeLines={codeSamples || []}
            callingLine={callingLine}
            highlightedLine={highlightedLine}
            focusedEndLine={focusedEndLine}
            topic={`Union Type Example`}
            subTopicId={subTopicId}
            language={language}
            onOpenModal={() => {
              setInputNumber(number);
              setInputLetter(letter);
              setInputCandy(candy);
              setModalVisible(true);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="visualize-card shadow-lg p-3">
            <h3 className="section-title">Canvas</h3>
            <Controls
              isRunning={isRunning}
              isPaused={isPaused}
              delay={delay}
              setDelay={setDelay}
              runVisualization={runUnionType}
              setIsPaused={setIsPaused}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
            <VisualizationCanvas
              variables={variables}
              highlightedVariables={highlightedVariables}
              unionVariables={unionVariables}
              highlightedUnionVariables={highlightedUnionVariables}
              stringVariables={stringVariables}
              highlightedStringVariables={highlightedStringVariables}
              highlightedStringIndices={[]}
            />
            <LoggerDrawer logs={logs} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-4">
          <AlgorithmDescription />
      </div>
      <UpdateVariablesModal
        isVisible={modalVisible}
        number={inputNumber}
        letter={inputLetter}
        candy={inputCandy}
        setNumber={setInputNumber}
        setLetter={setInputLetter}
        setCandy={setInputCandy}
        onSave={handleUpdateVariables}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

export default UnionType;
