import { useState, useEffect, useRef } from 'react';
import { 
  linkedListJava,
  linkedListPython,
  linkedListCpp,
  linkedListC,
  linkedListDeletionJava,
  linkedListDeletionPython,
  linkedListDeletionCpp,
  linkedListDeletionC
} from './linkedListAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useLinkedListDeletion = (language, data, targetData) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [nodeVariables, setNodeVariables] = useState({});
  const [headVariables, setHeadVariables] = useState({});
  const [highlightedHeadVariables, setHighlightedHeadVariables] = useState([]);
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedNodeVariables, setHighlightedNodeVariables] = useState([]);
  const [highlightDataPart, setHighlightDataPart] = useState([]);
  const [highlightNextAddressPart, setHighlightNextAddressPart] = useState([]);
  const [highlightNodeAddress, setHighlightNodeAddress] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  const hasRun = useRef(false);
  const [globalList, setGlobalList] = useState(null);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newTargetData) => {
    resetState();
    createLinkedListVisual(language);

    let updatedCodeSamples = "";

    // Dynamically update the code samples
    if (language === "C++") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/deleteNode\s*\(\s*\d+\s*\)/g, `deleteNode(${newTargetData})`)
      );
    } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/delete\s*\(\s*\d+\s*\)/g, `delete(${newTargetData})`)
      );
    }

    setCodeSamples(updatedCodeSamples);
  };

  const createLinkedListVisual = (language) => {
    if (language === 'Python') {
      createLinkedListPython();
    } else if (language === 'Java') {
      createLinkedListJava();
    } else if (language === 'C') {
      createLinkedListC();
    } else if (language === 'C++') {
      createLinkedListCpp();
    }
  }

  useEffect(() => {
    if (!hasRun.current) {
      createLinkedListVisual(language);
      hasRun.current = true;
    }
  }, []);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setNodeVariables({});
    setHeadVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedNodeVariables([]);
    setHighlightedHeadVariables([]);
    setHighlightDataPart([]);
    setHighlightNextAddressPart([]);
    setHighlightNodeAddress([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {

    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async (time=null) => {
    let start = Date.now();
    let ms;
    if (time === null) {
        ms = delayRef.current;
    } else {
        ms = time;
    }
    
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const createLinkedListPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'None' }
    }));

    const list = await linkedListPython(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const createLinkedListJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'null' }
    }));

    const list = await linkedListJava(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const runLinkedListDeletionJava = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListDeletionJava(
      targetData,
      globalList,
      setVariables,
      setNodeVariables,
      setHeadVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHighlightedVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(41);
    setIsRunning(false);
  };

  const runLinkedListDeletionPython = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListDeletionPython(
      targetData,
      globalList,
      setVariables,
      setNodeVariables,
      setHeadVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHighlightedVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(31);
    setIsRunning(false);
  };

  const createLinkedListCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'nullptr' }
    }));

    const list = await linkedListCpp(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const runLinkedListDeletionCpp = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListDeletionCpp(
      targetData,
      globalList,
      setVariables,
      setNodeVariables,
      setHeadVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHighlightedVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(58);
    setIsRunning(false);
  };

  const createLinkedListC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    setHeadVariables((vars) => ({ ...vars, 
      head: { variable_name: 'head', value: 'nullptr' }
    }));

    const list = await linkedListC(
      data,
      setNodeVariables,
      setHeadVariables
    );

    setGlobalList(list);

    setIsRunning(false);
  };

  const runLinkedListDeletionC = async () => {
    setFocusedEndLine(null);
    setLogs([]);
    setIsRunning(true);
    setIsPaused(false);

    await linkedListDeletionC(
      targetData,
      globalList,
      setVariables,
      setNodeVariables,
      setHeadVariables,
      highlightLine,
      highlightCallingLine,
      customSleep,
      logMessage,
      setHighlightedVariables,
      setHighlightedNodeVariables,
      setHighlightedHeadVariables,
      setHighlightNextAddressPart,
      setHighlightNodeAddress,
      setHighlightDataPart
    );

    await focusEndLine(43);
    setIsRunning(false);
  };


  const runLinkedList = async () => {
    if (language === 'Python') {
      await runLinkedListDeletionPython();
    } else if (language === 'Java') {
      await runLinkedListDeletionJava();
    } else if (language === 'C') {
      await runLinkedListDeletionC();
    } else if (language === 'C++') {
      await runLinkedListDeletionCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    nodeVariables,
    headVariables,
    highlightedVariables,
    highlightedNodeVariables,
    highlightedHeadVariables,
    highlightDataPart,
    highlightNextAddressPart,
    highlightNodeAddress,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setNodeVariables,
    setHeadVariables,
    setHighlightedVariables,
    setHighlightedNodeVariables,
    setHighlightedHeadVariables,
    setHighlightDataPart,
    setHighlightNextAddressPart,
    setHighlightNodeAddress,
    runLinkedList
  };
};
