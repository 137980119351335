import { useState, useEffect, useRef } from 'react';
import { mergeSortPython, mergeSortJava, mergeSortC, mergeSortCpp } from './mergeSortAlgorithms';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useMergeSort = (language, arr) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [highlightedIndices, setHighlightedIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newList) => {
    resetState();

     // Dynamically update the code samples
     let updatedCodeSamples = "";
     if (language === 'Python') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/arr = \[.*?\]/, `arr = [${newList.join(", ")}]`)
      );
     } else if (language === 'C++') {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/arr\s*=\s*{[\d\s,]*}/g, `arr = {${newList.join(", ")}}`)
      );
     } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(
            /int arr\[\] = \{.*?\};/,
            `int arr[] = {${newList.join(", ")}};`
          )
      );
     }
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setArrayVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setHighlightedArrayVariables([]);
    setHighlightedIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const highlightCallingLine = async (lineNumber) => {
    setCallingLine(lineNumber);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runMergeSortPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(31);
    setArrayVariables({ arr: { variable_name: 'arr', value: arr, parent: '' } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedIndices([]);
    setHighlightedArrayVariables([]);

    await highlightLine(32);
    await customSleep();

    await highlightCallingLine(32);
    await mergeSortPython(arr, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(32);
    await customSleep();

    await highlightLine(33);
    await logMessage(`Sorted list: ${arr}`);
    await customSleep();

    await focusEndLine(12);
    setIsRunning(false);
  };

  const runMergeSortJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(37);
    setArrayVariables({ arr: { variable_name: 'arr', value: arr, parent: '' } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedIndices([]);
    setHighlightedArrayVariables([]);

    await highlightLine(38);
    await customSleep();

    await highlightCallingLine(38);
    await mergeSortJava(arr, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(38);
    await customSleep();

    await highlightLine(39);
    await logMessage(`Sorted array is: ${arr}`);
    await customSleep();

    await focusEndLine(41);
    setIsRunning(false);
  };

  const runMergeSortC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(41);
    setArrayVariables({ arr: { variable_name: 'arr', value: arr, parent: '' } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedIndices([]);
    setHighlightedArrayVariables([]);

    await highlightLine(42);
    let n = arr.length;
    setVariables((vars) => ({ ...vars, n: { variable_name: 'n', value: n } }));
    setHighlightedVariables(['n']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(44);
    await customSleep();

    await highlightCallingLine(44);
    await mergeSortC(arr, n, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(44);
    await customSleep();

    await highlightLine(46);
    await logMessage(`Sorted array is: `);
    await customSleep();

    for(let itr = 0; itr < arr.length; itr++) {
      await highlightLine(47);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr', 'n']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(48);
      setHighlightedIndices([{ arrayName: 'arr', index: itr, iteratorName: 'itr' }]);
      await logMessage(arr[itr]);
      await customSleep();
      setHighlightedIndices([]);
    }

    await highlightLine(50);
    await customSleep();

    await focusEndLine(51);
    setIsRunning(false);
  };

  const runMergeSortCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(33);
    setArrayVariables({ arr: { variable_name: 'arr', value: arr, parent: '' } });
    setHighlightedArrayVariables(['arr']);
    await customSleep();
    setHighlightedIndices([]);
    setHighlightedArrayVariables([]);

    await highlightLine(34);
    await customSleep();

    await highlightCallingLine(34);
    await mergeSortCpp(arr, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep);
    await highlightLine(34);
    await customSleep();

    await highlightLine(35);
    await logMessage(`Sorted array is: `);
    await customSleep();

    for(let itr = 0; itr < arr.length; itr++) {
      await highlightLine(36);
      setVariables((vars) => ({ ...vars, itr: { variable_name: 'itr', value: itr } }));
      setHighlightedVariables(['itr']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(37);
      setHighlightedIndices([{ arrayName: 'arr', index: itr, iteratorName: 'itr' }]);
      await logMessage(arr[itr]);
      await customSleep();
      setHighlightedIndices([]);
    }

    await highlightLine(39);
    await customSleep();

    await focusEndLine(40);
    setIsRunning(false);
  };

  const runMergeSort = async () => {
    if (language === 'Python') {
      await runMergeSortPython();
    } else if (language === 'Java') {
      await runMergeSortJava();
    } else if (language === 'C') {
      await runMergeSortC();
    } else if (language === 'C++') {
      await runMergeSortCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    highlightedIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    setHighlightedIndices,
    runMergeSort
  };
};
