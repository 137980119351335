import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useDoubleTypes = (language, earthMass, avogadroNumber, electronCharge) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [callingLine, setCallingLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const isPausedRef = useRef(isPaused);
  const [delay, setDelay] = useState(2000);
  const delayRef = useRef(delay);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [highlightedArrayVariables, setHighlightedArrayVariables] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  

  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newEarthMass, newAvogadroNumber, newElectronCharge) => {
    resetState();
    
    // Dynamically update the code samples
    const updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
      line
        .replace(/earthMass\s*=\s*[\d.e-]+;/, `earthMass = ${newEarthMass};`)
        .replace(/avogadroNumber\s*=\s*[\d.e-]+;/, `avogadroNumber = ${newAvogadroNumber};`)
        .replace(/electronCharge\s*=\s*[\d.e-]+;/, `electronCharge = ${newElectronCharge};`)
    );
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setCallingLine(null);
    setVariables({});
    setLogs([]);
    setHighlightedVariables([]);
    setArrayVariables({});
    setHighlightedArrayVariables([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setCallingLine((prevCallingLine) => {
      if (prevCallingLine === lineNumber) {
        return null;
      }
      return prevCallingLine;
    });
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runDoubleTypesJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, earthMass: { variable_name: 'earthMass', value: earthMass } }));
    setHighlightedVariables(['earthMass']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, avogadroNumber: { variable_name: 'avogadroNumber', value: avogadroNumber } }));
    setHighlightedVariables(['avogadroNumber']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, electronCharge: { variable_name: 'electronCharge', value: electronCharge } }));
    setHighlightedVariables(['electronCharge']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['earthMass']);
    await logMessage(`Earth's mass in kilograms: ${earthMass}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['avogadroNumber']);
    await logMessage(`Avogadro's number: ${avogadroNumber}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['electronCharge']);
    await logMessage(`Electron charge in coulombs: ${electronCharge}`);
    await customSleep();
    setHighlightedVariables([]);

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runDoubleTypesC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setVariables((vars) => ({ ...vars, earthMass: { variable_name: 'earthMass', value: earthMass } }));
    setHighlightedVariables(['earthMass']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, avogadroNumber: { variable_name: 'avogadroNumber', value: avogadroNumber } }));
    setHighlightedVariables(['avogadroNumber']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, electronCharge: { variable_name: 'electronCharge', value: electronCharge } }));
    setHighlightedVariables(['electronCharge']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);
    setHighlightedVariables(['earthMass']);
    await logMessage(`Earth's mass in kilograms: ${earthMass}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(8);
    setHighlightedVariables(['avogadroNumber']);
    await logMessage(`Avogadro's number: ${avogadroNumber}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(10);
    setHighlightedVariables(['electronCharge']);
    await logMessage(`Electron charge in coulombs: ${electronCharge}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(12);
    await customSleep();

    await focusEndLine(13);
    setIsRunning(false);
  };

  const runDoubleTypesCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setVariables((vars) => ({ ...vars, earthMass: { variable_name: 'earthMass', value: earthMass } }));
    setHighlightedVariables(['earthMass']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(4);
    setVariables((vars) => ({ ...vars, avogadroNumber: { variable_name: 'avogadroNumber', value: avogadroNumber } }));
    setHighlightedVariables(['avogadroNumber']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(5);
    setVariables((vars) => ({ ...vars, electronCharge: { variable_name: 'electronCharge', value: electronCharge } }));
    setHighlightedVariables(['electronCharge']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(7);
    setHighlightedVariables(['earthMass']);
    await logMessage(`Earth's mass in kilograms: ${earthMass}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(9);
    setHighlightedVariables(['avogadroNumber']);
    await logMessage(`Avogadro's number: ${avogadroNumber}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(11);
    setHighlightedVariables(['electronCharge']);
    await logMessage(`Electron charge in coulombs: ${electronCharge}`);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(13);
    await customSleep();

    await focusEndLine(14);
    setIsRunning(false);
  };

  const runDoubleTypes = async () => {
    if (language === 'Java') {
      await runDoubleTypesJava();
    } else if (language === 'C') {
      await runDoubleTypesC();
    } else if (language === 'C++') {
      await runDoubleTypesCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    callingLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    highlightedVariables,
    highlightedArrayVariables,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setCallingLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setHighlightedVariables,
    setHighlightedArrayVariables,
    runDoubleTypes
  };
};
