let level = 0;
let levelL = 0;
let levelR = 0;
export const mergeSortPython = async (arr, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, childName=null ) => {
  await highlightLine(1);
  await customSleep();
  if (arr.length > 1) {
      await highlightLine(2);
      let mid = Math.floor(arr.length / 2);
      setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
      setHighlightedVariables(['mid']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(3);

      if(childName === "L" && levelL === 0) {
        levelL += 2;
        level = levelL;
      } else if(childName === "R" && levelR === 0) {
        levelR += 2;
        level = levelR;
      } else {
        level += 1;
      }

      let parentArrayName = "";
      if(childName === "L") {
        parentArrayName = `${childName}-L${level - 1}`;
      } else if(childName === "R") {
        parentArrayName = `${childName}-R${level - 1}`;
      } else {
        parentArrayName = "arr";
      }

      let highLightIndices = [];
      for(let i = 0; i < mid; i++) {
        highLightIndices.push({
          arrayName: parentArrayName, index: i, iteratorName: ''
        });
      }
      setHighlightedIndices(highLightIndices);
      let L = arr.slice(0, mid);

      let variableNameL = "";
      if (childName !== null) {
        variableNameL = `${childName}-L${level}`;
      } else {
        variableNameL = `L-L${level}`;
      }
      // setArrayVariables((vars) => ({ ...vars, [variableNameL]: { variable_name: variableNameL, value: L } }));
      setArrayVariables((vars) => ({ ...vars, [variableNameL]: { variable_name: "L", value: L, parent: parentArrayName } }));
      setHighlightedArrayVariables([variableNameL]);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedArrayVariables([]);

      await highlightLine(4);
      highLightIndices = [];
      for(let i = mid; i < arr.length; i++) {
        highLightIndices.push({
          arrayName: parentArrayName, index: i, iteratorName: ''
        });
      }
      setHighlightedIndices(highLightIndices);
      let R = arr.slice(mid);
      let variableNameR = "";
      if (childName !== null) {
        variableNameR = `${childName}-R${level}`;
      } else {
        variableNameR = `R-R${level}`;
      }
      // setArrayVariables((vars) => ({ ...vars, [variableNameR]: { variable_name: variableNameR, value: R } }));
      setArrayVariables((vars) => ({ ...vars, [variableNameR]: { variable_name: "R", value: R, parent: parentArrayName } }));
      setHighlightedArrayVariables([variableNameR]);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedArrayVariables([]);
  
      await highlightLine(6);
      highLightIndices = [];
      for(let i = 0; i < L.length; i++) {
        highLightIndices.push({
          arrayName: variableNameL, index: i, iteratorName: ''
        });
      }
      setHighlightedIndices(highLightIndices);
      await customSleep();
      await highlightCallingLine(6);
      setHighlightedIndices([]);
      await mergeSortPython(L, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "L");
      await highlightLine(6);
      await customSleep();

      await highlightLine(7);
      highLightIndices = [];
      for(let i = 0; i < R.length; i++) {
        highLightIndices.push({
          arrayName: variableNameR, index: i, iteratorName: ''
        });
      }
      setHighlightedIndices(highLightIndices);
      await customSleep();
      await highlightCallingLine(7);
      setHighlightedIndices([]);
      await mergeSortPython(R, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "R");
      await highlightLine(7);
      await customSleep();
      setHighlightedIndices([]);

      level -= 1;
  
      await highlightLine(9);
      let i = 0, j = 0, k = 0;
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, j: { variable_name: 'j', value: j }, k: { variable_name: 'k', value: k } }));
      setHighlightedVariables(['i', 'j', 'k']);
      await customSleep();
      setHighlightedVariables([]);

      if (!(i < L.length && j < R.length)) {
        await highlightLine(11);
        setHighlightedVariables(['i', 'j']);
        await customSleep();
      }
      while (i < L.length && j < R.length) {
        await highlightLine(11);
        setHighlightedVariables(['i', 'j']);
        await customSleep();

        await highlightLine(12);
        setHighlightedVariables(['i', 'j']);
        setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
        await customSleep();
        setHighlightedIndices([]);
        if (L[i] < R[j]) {
          await highlightLine(13);
          setHighlightedVariables(['k', 'i']);

          let variable = '';
          if(childName === null) {
            variable = 'arr';
          } else {
            variable = `${childName}-${childName}${level}`;
          }
          setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
          arr[k] = L[i];
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedIndices([]);

          await highlightLine(14);
          setHighlightedVariables(['i']);
          i += 1;
          setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
          await customSleep();
          setHighlightedVariables([]);
        } else {
          await highlightLine(12);
          setHighlightedVariables(['i', 'j']);
          setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
          await customSleep();

          await highlightLine(15);
          await customSleep();

          await highlightLine(16);
          setHighlightedVariables(['k', 'j']);

          let variable = '';
          if(childName === null) {
            variable = 'arr';
          } else {
            variable = `${childName}-${childName}${level}`;
          }
          setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
          arr[k] = R[j];
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedIndices([]);

          await highlightLine(17);
          setHighlightedVariables(['j']);
          j += 1;
          setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
          await customSleep();
          setHighlightedVariables([]);
        }

        await highlightLine(18);
        setHighlightedVariables(['k']);
        k += 1;
        setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k } }));
        await customSleep();
        setHighlightedVariables([]);
      }
  
      if (!(i < L.length)) {
        await highlightLine(20);
        setHighlightedVariables(['i']);
        await customSleep();
      }
      while (i < L.length) {
        await highlightLine(20);
        setHighlightedVariables(['i']);
        await customSleep();

        await highlightLine(21);
        setHighlightedVariables(['k', 'i']);

        let variable = '';
          if(childName === null) {
            variable = 'arr';
          } else {
            variable = `${childName}-${childName}${level}`;
          }
        setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
        arr[k] = L[i];
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedIndices([]);

        await highlightLine(22);
        setHighlightedVariables(['i']);
        i += 1;
        setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(23);
        setHighlightedVariables(['k']);
        k += 1;
        setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k } }));
        await customSleep();
        setHighlightedVariables([]);
      }
  
      if (!(j < R.length)) {
        await highlightLine(25);
        setHighlightedVariables(['j']);
        await customSleep();
      }
      while (j < R.length) {
        await highlightLine(25);
        setHighlightedVariables(['j']);
        await customSleep();

        await highlightLine(26);
        setHighlightedVariables(['k', 'j']);

        let variable = '';
          if(childName === null) {
            variable = 'arr';
          } else {
            variable = `${childName}-${childName}${level}`;
          }
        setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
        arr[k] = R[j];
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedIndices([]);

        await highlightLine(27);
        setHighlightedVariables(['j']);
        j += 1;
        setVariables((vars) => ({ ...vars, j: { variable_name: 'j', value: j } }));
        await customSleep();
        setHighlightedVariables([]);

        await highlightLine(28);
        setHighlightedVariables(['k']);
        k += 1;
        setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k } }));
        await customSleep();
        setHighlightedVariables([]);
      }
    }
  };

  export const mergeSortJava = async (arr, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, childName=null) => {
      await highlightLine(4);
      await customSleep();

      await highlightLine(5);
      await customSleep();
      if (arr.length > 1) {
          await highlightLine(6);
          let mid = Math.floor(arr.length / 2);
          setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
          setHighlightedVariables(['mid']);
          await customSleep();
          setHighlightedVariables([]);
  
          await highlightLine(8);
  
          if (childName === "L" && levelL === 0) {
              levelL += 2;
              level = levelL;
          } else if (childName === "R" && levelR === 0) {
              levelR += 2;
              level = levelR;
          } else {
              level += 1;
          }
  
          let parentArrayName = "";
          if (childName === "L") {
              parentArrayName = `${childName}-L${level - 1}`;
          } else if (childName === "R") {
              parentArrayName = `${childName}-R${level - 1}`;
          } else {
              parentArrayName = "arr";
          }
  
          let highLightIndices = [];
          for (let i = 0; i < mid; i++) {
              highLightIndices.push({
                  arrayName: parentArrayName, index: i, iteratorName: ''
              });
          }
          setHighlightedIndices(highLightIndices);
          let L = new Array(mid).fill("");
  
          let variableNameL = "";
          if (childName !== null) {
              variableNameL = `${childName}-L${level}`;
          } else {
              variableNameL = `L-L${level}`;
          }
          setArrayVariables((vars) => ({ ...vars, [variableNameL]: { variable_name: "L", value: L, parent: parentArrayName } }));
          setHighlightedArrayVariables([variableNameL]);
          await customSleep();
          setHighlightedIndices([]);
          setHighlightedArrayVariables([]);
  
          await highlightLine(9);
          highLightIndices = [];
          for (let i = mid; i < arr.length; i++) {
              highLightIndices.push({
                  arrayName: parentArrayName, index: i, iteratorName: ''
              });
          }
          setHighlightedIndices(highLightIndices);
          let R = new Array(arr.length - mid).fill("");
          let variableNameR = "";
          if (childName !== null) {
              variableNameR = `${childName}-R${level}`;
          } else {
              variableNameR = `R-R${level}`;
          }
          setArrayVariables((vars) => ({ ...vars, [variableNameR]: { variable_name: "R", value: R, parent: parentArrayName } }));
          setHighlightedArrayVariables([variableNameR]);
          await customSleep();
          setHighlightedIndices([]);
          setHighlightedArrayVariables([]);

          await highlightLine(11);
          L = arr.slice(0, mid);
          setArrayVariables((vars) => ({ ...vars, [variableNameL]: { variable_name: "L", value: L, parent: parentArrayName } }));
          setHighlightedArrayVariables([variableNameL]);
          await customSleep();
          setHighlightedIndices([]);
          setHighlightedArrayVariables([]);

          await highlightLine(12);
          R = arr.slice(mid);
          setArrayVariables((vars) => ({ ...vars, [variableNameR]: { variable_name: "R", value: R, parent: parentArrayName } }));
          setHighlightedArrayVariables([variableNameR]);
          await customSleep();
          setHighlightedIndices([]);
          setHighlightedArrayVariables([]);
  
          await highlightLine(14);
          highLightIndices = [];
          for (let i = 0; i < L.length; i++) {
              highLightIndices.push({
                  arrayName: variableNameL, index: i, iteratorName: ''
              });
          }
          setHighlightedIndices(highLightIndices);
          await customSleep();
          await highlightCallingLine(14);
          setHighlightedIndices([]);
          await mergeSortJava(L, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "L");
          await highlightLine(14);
          await customSleep();
  
          await highlightLine(15);
          highLightIndices = [];
          for (let i = 0; i < R.length; i++) {
              highLightIndices.push({
                  arrayName: variableNameR, index: i, iteratorName: ''
              });
          }
          setHighlightedIndices(highLightIndices);
          await customSleep();
          await highlightCallingLine(15);
          setHighlightedIndices([]);
          await mergeSortJava(R, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "R");
          await highlightLine(15);
          await customSleep();
          setHighlightedIndices([]);
  
          level -= 1;
  
          await highlightLine(17);
          let i = 0, j = 0, k = 0;
          setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, j: { variable_name: 'j', value: j }, k: { variable_name: 'k', value: k } }));
          setHighlightedVariables(['i', 'j', 'k']);
          await customSleep();
          setHighlightedVariables([]);
  
          if (!(i < L.length && j < R.length)) {
              await highlightLine(18);
              setHighlightedVariables(['i', 'j']);
              await customSleep();
          }
          while (i < L.length && j < R.length) {
              await highlightLine(18);
              setHighlightedVariables(['i', 'j']);
              await customSleep();
  
              await highlightLine(19);
              setHighlightedVariables(['i', 'j']);
              setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
              await customSleep();
              setHighlightedIndices([]);
              if (L[i] < R[j]) {
                  await highlightLine(20);
                  setHighlightedVariables(['k', 'i']);
  
                  let variable = '';
                  if (childName === null) {
                      variable = 'arr';
                  } else {
                      variable = `${childName}-${childName}${level}`;
                  }
                  setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
                  arr[k] = L[i];
                  k += 1;
                  i += 1;
                  setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, i: { variable_name: 'i', value: i } }));
                  await customSleep();
                  setHighlightedVariables([]);
                  setHighlightedIndices([]);
              } else {
                  await highlightLine(19);
                  setHighlightedVariables(['i', 'j']);
                  setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);

                  await highlightLine(21);
                  await customSleep();
                  setHighlightedIndices([]);
  
                  await highlightLine(22);
                  setHighlightedVariables(['k', 'j']);
  
                  let variable = '';
                  if (childName === null) {
                      variable = 'arr';
                  } else {
                      variable = `${childName}-${childName}${level}`;
                  }
                  setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
                  arr[k] = R[j];
                  k += 1;
                  j += 1;
                  setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, j: { variable_name: 'j', value: j } }));
                  await customSleep();
                  setHighlightedVariables([]);
                  setHighlightedIndices([]);
              }
          }
  
          if (!(i < L.length)) {
              await highlightLine(26);
              setHighlightedVariables(['i']);
              await customSleep();
          }
          while (i < L.length) {
              await highlightLine(26);
              setHighlightedVariables(['i']);
              await customSleep();
  
              await highlightLine(27);
              setHighlightedVariables(['k', 'i']);
  
              let variable = '';
              if (childName === null) {
                  variable = 'arr';
              } else {
                  variable = `${childName}-${childName}${level}`;
              }
              setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
              arr[k] = L[i];
              k += 1;
              i += 1;
              setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, i: { variable_name: 'i', value: i } }));
              await customSleep();
              setHighlightedVariables([]);
              setHighlightedIndices([]);
          }
  
          if (!(j < R.length)) {
              await highlightLine(30);
              setHighlightedVariables(['j']);
              await customSleep();
          }
          while (j < R.length) {
              await highlightLine(30);
              setHighlightedVariables(['j']);
              await customSleep();
  
              await highlightLine(31);
              setHighlightedVariables(['k', 'j']);
  
              let variable = '';
              if (childName === null) {
                  variable = 'arr';
              } else {
                  variable = `${childName}-${childName}${level}`;
              }
              setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
              arr[k] = R[j];
              k += 1;
              j += 1;
              setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, j: { variable_name: 'j', value: j } }));
              await customSleep();
              setHighlightedVariables([]);
              setHighlightedIndices([]);
          }
      }
  };
  

  export const mergeSortC = async (arr, n, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, childName=null) => {
    await highlightLine(4);
    await customSleep();

    await highlightLine(5);
    setHighlightedVariables(['n']);
    await customSleep();
    setHighlightedVariables([]);
    if (n > 1) {
      await highlightLine(6);
      let mid = Math.floor(arr.length / 2);
      setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
      setHighlightedVariables(['mid']);
      await customSleep();
      setHighlightedVariables([]);
  
      await highlightLine(8);
  
      if (childName === "L" && levelL === 0) {
          levelL += 2;
          level = levelL;
      } else if (childName === "R" && levelR === 0) {
          levelR += 2;
          level = levelR;
      } else {
          level += 1;
      }
  
      let parentArrayName = "";
      if (childName === "L") {
          parentArrayName = `${childName}-L${level - 1}`;
      } else if (childName === "R") {
          parentArrayName = `${childName}-R${level - 1}`;
      } else {
          parentArrayName = "arr";
      }
  
      let highLightIndices = [];
      for (let i = 0; i < mid; i++) {
          highLightIndices.push({
              arrayName: parentArrayName, index: i, iteratorName: ''
          });
      }
      setHighlightedIndices(highLightIndices);
      let L = new Array(mid).fill("");
  
      let variableNameL = "";
      if (childName !== null) {
          variableNameL = `${childName}-L${level}`;
      } else {
          variableNameL = `L-L${level}`;
      }
      setArrayVariables((vars) => ({ ...vars, [variableNameL]: { variable_name: "L", value: L, parent: parentArrayName } }));
      setHighlightedArrayVariables([variableNameL]);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedArrayVariables([]);
  
      await highlightLine(9);
      highLightIndices = [];
      for (let i = mid; i < arr.length; i++) {
          highLightIndices.push({
              arrayName: parentArrayName, index: i, iteratorName: ''
          });
      }
      setHighlightedIndices(highLightIndices);
      let R = new Array(arr.length - mid).fill("");
      let variableNameR = "";
      if (childName !== null) {
          variableNameR = `${childName}-R${level}`;
      } else {
          variableNameR = `R-R${level}`;
      }
      setArrayVariables((vars) => ({ ...vars, [variableNameR]: { variable_name: "R", value: R, parent: parentArrayName } }));
      setHighlightedArrayVariables([variableNameR]);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedArrayVariables([]);

      await highlightLine(11);
      L = arr.slice(0, mid);
      setArrayVariables((vars) => ({ ...vars, [variableNameL]: { variable_name: "L", value: L, parent: parentArrayName } }));
      setHighlightedArrayVariables([variableNameL]);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedArrayVariables([]);

      await highlightLine(12);
      R = arr.slice(mid);
      setArrayVariables((vars) => ({ ...vars, [variableNameR]: { variable_name: "R", value: R, parent: parentArrayName } }));
      setHighlightedArrayVariables([variableNameR]);
      await customSleep();
      setHighlightedIndices([]);
      setHighlightedArrayVariables([]);
  
      await highlightLine(14);
      highLightIndices = [];
      for (let i = 0; i < L.length; i++) {
          highLightIndices.push({
              arrayName: variableNameL, index: i, iteratorName: ''
          });
      }
      setHighlightedIndices(highLightIndices);
      await customSleep();
      await highlightCallingLine(14);
      setHighlightedIndices([]);
      await mergeSortC(L, mid, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "L");
      await highlightLine(14);
      await customSleep();
  
      await highlightLine(15);
      highLightIndices = [];
      for (let i = 0; i < R.length; i++) {
          highLightIndices.push({
              arrayName: variableNameR, index: i, iteratorName: ''
          });
      }
      setHighlightedIndices(highLightIndices);
      await customSleep();
      await highlightCallingLine(15);
      setHighlightedIndices([]);
      await mergeSortC(R, n - mid, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "R");
      await highlightLine(15);
      await customSleep();
      setHighlightedIndices([]);
  
      level -= 1;
  
      await highlightLine(17);
      let i = 0, j = 0, k = 0;
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, j: { variable_name: 'j', value: j }, k: { variable_name: 'k', value: k } }));
      setHighlightedVariables(['i', 'j', 'k']);
      await customSleep();
      setHighlightedVariables([]);
  
      if (!(i < mid && j < (n - mid))) {
          await highlightLine(19);
          setHighlightedVariables(['i', 'mid', 'j', 'n']);
          await customSleep();
      }
      while (i < mid && j < (n - mid)) {
          await highlightLine(19);
          setHighlightedVariables(['i', 'mid', 'j', 'n']);
          await customSleep();
  
          await highlightLine(20);
          setHighlightedVariables(['i', 'j']);
          setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
          await customSleep();
          setHighlightedIndices([]);
          if (L[i] < R[j]) {
              await highlightLine(21);
              setHighlightedVariables(['k', 'i']);
  
              let variable = '';
              if (childName === null) {
                  variable = 'arr';
              } else {
                  variable = `${childName}-${childName}${level}`;
              }
              setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
              arr[k] = L[i];
              k += 1;
              i += 1;
              setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, i: { variable_name: 'i', value: i } }));
              await customSleep();
              setHighlightedVariables([]);
              setHighlightedIndices([]);
          } else {
              await highlightLine(22);
              setHighlightedVariables(['i', 'j']);
              setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
              await customSleep();
              setHighlightedIndices([]);
  
              await highlightLine(23);
              setHighlightedVariables(['k', 'j']);
  
              let variable = '';
              if (childName === null) {
                  variable = 'arr';
              } else {
                  variable = `${childName}-${childName}${level}`;
              }
              setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
              arr[k] = R[j];
              k += 1;
              j += 1;
              setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, j: { variable_name: 'j', value: j } }));
              await customSleep();
              setHighlightedVariables([]);
              setHighlightedIndices([]);
          }
      }
  
      if (!(i < mid)) {
          await highlightLine(27);
          setHighlightedVariables(['i', 'mid']);
          await customSleep();
      }
      while (i < mid) {
          await highlightLine(27);
          setHighlightedVariables(['i', 'mid']);
          await customSleep();
  
          await highlightLine(28);
          setHighlightedVariables(['k', 'i']);
  
          let variable = '';
          if (childName === null) {
              variable = 'arr';
          } else {
              variable = `${childName}-${childName}${level}`;
          }
          setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
          arr[k] = L[i];
          k += 1;
          i += 1;
          setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, i: { variable_name: 'i', value: i } }));
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedIndices([]);
      }
  
      if (!(j < (n - mid))) {
          await highlightLine(31);
          setHighlightedVariables(['j', 'n', 'mid']);
          await customSleep();
      }
      while (j < (n - mid)) {
          await highlightLine(31);
          setHighlightedVariables(['j', 'n', 'mid']);
          await customSleep();
  
          await highlightLine(32);
          setHighlightedVariables(['k', 'j']);
  
          let variable = '';
          if (childName === null) {
              variable = 'arr';
          } else {
              variable = `${childName}-${childName}${level}`;
          }
          setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
          arr[k] = R[j];
          k += 1;
          j += 1;
          setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, j: { variable_name: 'j', value: j } }));
          await customSleep();
          setHighlightedVariables([]);
          setHighlightedIndices([]);
      }
      await highlightLine(35);
      await customSleep();

      await highlightLine(36);
      await customSleep();
    }
};

  
export const mergeSortCpp = async (arr, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, childName=null) => {
  await highlightLine(3);
  await customSleep();

  await highlightLine(4);
  await customSleep();
  
  if (arr.length > 1) {
    await highlightLine(5);
    let mid = Math.floor(arr.length / 2);
    setVariables((vars) => ({ ...vars, mid: { variable_name: 'mid', value: mid } }));
    setHighlightedVariables(['mid']);
    await customSleep();
    setHighlightedVariables([]);

    await highlightLine(6);

    if (childName === "L" && levelL === 0) {
        levelL += 2;
        level = levelL;
    } else if (childName === "R" && levelR === 0) {
        levelR += 2;
        level = levelR;
    } else {
        level += 1;
    }

    let parentArrayName = "";
    if (childName === "L") {
        parentArrayName = `${childName}-L${level - 1}`;
    } else if (childName === "R") {
        parentArrayName = `${childName}-R${level - 1}`;
    } else {
        parentArrayName = "arr";
    }

    let highLightIndices = [];
    for (let i = 0; i < mid; i++) {
        highLightIndices.push({
            arrayName: parentArrayName, index: i, iteratorName: ''
        });
    }
    setHighlightedIndices(highLightIndices);
    let L = arr.slice(0, mid);

    let variableNameL = "";
    if (childName !== null) {
        variableNameL = `${childName}-L${level}`;
    } else {
        variableNameL = `L-L${level}`;
    }
    setArrayVariables((vars) => ({ ...vars, [variableNameL]: { variable_name: "L", value: L, parent: parentArrayName } }));
    setHighlightedArrayVariables([variableNameL]);
    await customSleep();
    setHighlightedIndices([]);
    setHighlightedArrayVariables([]);

    await highlightLine(7);
    highLightIndices = [];
    for (let i = mid; i < arr.length; i++) {
        highLightIndices.push({
            arrayName: parentArrayName, index: i, iteratorName: ''
        });
    }
    setHighlightedIndices(highLightIndices);
    let R = arr.slice(mid);
    let variableNameR = "";
    if (childName !== null) {
        variableNameR = `${childName}-R${level}`;
    } else {
        variableNameR = `R-R${level}`;
    }
    setArrayVariables((vars) => ({ ...vars, [variableNameR]: { variable_name: "R", value: R, parent: parentArrayName } }));
    setHighlightedArrayVariables([variableNameR]);
    await customSleep();
    setHighlightedIndices([]);
    setHighlightedArrayVariables([]);

    await highlightLine(9);
    highLightIndices = [];
    for (let i = 0; i < L.length; i++) {
        highLightIndices.push({
            arrayName: variableNameL, index: i, iteratorName: ''
        });
    }
    setHighlightedIndices(highLightIndices);
    await customSleep();
    await highlightCallingLine(9);
    setHighlightedIndices([]);
    await mergeSortCpp(L, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "L");
    await highlightLine(9);
    await customSleep();

    await highlightLine(10);
    highLightIndices = [];
    for (let i = 0; i < R.length; i++) {
        highLightIndices.push({
            arrayName: variableNameR, index: i, iteratorName: ''
        });
    }
    setHighlightedIndices(highLightIndices);
    await customSleep();
    await highlightCallingLine(10);
    setHighlightedIndices([]);
    await mergeSortCpp(R, highlightLine, setVariables, setArrayVariables, setHighlightedVariables, setHighlightedArrayVariables, setHighlightedIndices, highlightCallingLine, customSleep, "R");
    await highlightLine(10);
    await customSleep();
    setHighlightedIndices([]);

    level -= 1;

    await highlightLine(12);
    let i = 0, j = 0, k = 0;
    setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i }, j: { variable_name: 'j', value: j }, k: { variable_name: 'k', value: k } }));
    setHighlightedVariables(['i', 'j', 'k']);
    await customSleep();
    setHighlightedVariables([]);

    if (!(i < L.length && j < R.length)) {
        await highlightLine(14);
        setHighlightedVariables(['i', 'j']);
        await customSleep();
    }
    while (i < L.length && j < R.length) {
        await highlightLine(14);
        setHighlightedVariables(['i', 'j']);
        await customSleep();

        await highlightLine(15);
        setHighlightedVariables(['i', 'j']);
        setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
        await customSleep();
        setHighlightedIndices([]);
        if (L[i] < R[j]) {
            await highlightLine(16);
            setHighlightedVariables(['k', 'i']);

            let variable = '';
            if (childName === null) {
                variable = 'arr';
            } else {
                variable = `${childName}-${childName}${level}`;
            }
            setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
            arr[k] = L[i];
            k += 1;
            i += 1;
            setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, i: { variable_name: 'i', value: i } }));
            await customSleep();
            setHighlightedVariables([]);
            setHighlightedIndices([]);
        } else {
            await highlightLine(17);
            setHighlightedVariables(['i', 'j']);
            setHighlightedIndices([{ arrayName: variableNameL, index: i, iteratorName: 'i' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
            await customSleep();
            setHighlightedIndices([]);

            await highlightLine(18);
            setHighlightedVariables(['k', 'j']);

            let variable = '';
            if (childName === null) {
                variable = 'arr';
            } else {
                variable = `${childName}-${childName}${level}`;
            }
            setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
            arr[k] = R[j];
            k += 1;
            j += 1;
            setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, j: { variable_name: 'j', value: j } }));
            await customSleep();
            setHighlightedVariables([]);
            setHighlightedIndices([]);
        }
    }

    if (!(i < L.length)) {
        await highlightLine(22);
        setHighlightedVariables(['i']);
        await customSleep();
    }
    while (i < L.length) {
        await highlightLine(22);
        setHighlightedVariables(['i']);
        await customSleep();

        await highlightLine(23);
        setHighlightedVariables(['k', 'i']);

        let variable = '';
        if (childName === null) {
            variable = 'arr';
        } else {
            variable = `${childName}-${childName}${level}`;
        }
        setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameL, index: i, iteratorName: 'i' }]);
        arr[k] = L[i];
        k += 1;
        i += 1;
        setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, i: { variable_name: 'i', value: i } }));
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedIndices([]);
    }

    if (!(j < R.length)) {
        await highlightLine(26);
        setHighlightedVariables(['j']);
        await customSleep();
    }
    while (j < R.length) {
        await highlightLine(26);
        setHighlightedVariables(['j']);
        await customSleep();

        await highlightLine(27);
        setHighlightedVariables(['k', 'j']);

        let variable = '';
        if (childName === null) {
            variable = 'arr';
        } else {
            variable = `${childName}-${childName}${level}`;
        }
        setHighlightedIndices([{ arrayName: variable, index: k, iteratorName: 'k' }, { arrayName: variableNameR, index: j, iteratorName: 'j' }]);
        arr[k] = R[j];
        k += 1;
        j += 1;
        setVariables((vars) => ({ ...vars, k: { variable_name: 'k', value: k }, j: { variable_name: 'j', value: j } }));
        await customSleep();
        setHighlightedVariables([]);
        setHighlightedIndices([]);
    }
  }
};
