import { useState, useEffect, useRef } from 'react';
import explanations from './explanations';
import codeSamplesTemplate from './codeSamples';
import { useSpeechSynthesis } from '../speechUtils';

export const useStringConcatenation = (language, str1, str2) => {
  const [highlightedLine, setHighlightedLine] = useState(null);
  const [focusedEndLine, setFocusedEndLine] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [delay, setDelay] = useState(2000);
  const [logs, setLogs] = useState([]);
  const [variables, setVariables] = useState({});
  const [arrayVariables, setArrayVariables] = useState({});
  const [highlightedVariables, setHighlightedVariables] = useState([]);
  const [stringVariables, setStringVariables] = useState({});
  const [highlightedStringVariables, setHighlightedStringVariables] = useState([]);
  const [highlightedStringIndices, setHighlightedStringIndices] = useState([]);
  const [codeSamples, setCodeSamples] = useState(codeSamplesTemplate[language]);
  const { speak, setIsMuted } = useSpeechSynthesis();
  const [isMuted, setLocalIsMuted] = useState(false);
  const isPausedRef = useRef(isPaused);
  const delayRef = useRef(delay);
  const isMutedRef = useRef(isMuted);


  useEffect(() => {
    setIsMuted(isMuted);
  }, [isMuted]);

  const updateVariables = (newStr1, newStr2) => {
    resetState();

     // Dynamically update the code samples
     let updatedCodeSamples = "";
     if (language === "C") {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/str1\[\]\s*=\s*".*?"/, `str1[] = "${newStr1}"`)
          .replace(/str2\[\]\s*=\s*".*?"/, `str2[] = "${newStr2}"`)
      );
     } else {
      updatedCodeSamples = codeSamplesTemplate[language].map((line) =>
        line
          .replace(/str1 = ".*?"/, `str1 = "${newStr1}"`)
          .replace(/str2 = ".*?"/, `str2 = "${newStr2}"`)
      );
     }
     
    setCodeSamples(updatedCodeSamples);
  };

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    delayRef.current = delay;
  }, [delay]);

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const resetState = () => {
    setHighlightedLine(null);
    setFocusedEndLine(null);
    setLogs([]);
    setVariables({});
    setHighlightedVariables([]);
    setStringVariables({});
    setHighlightedStringVariables([]);
    setHighlightedStringIndices([]);
  };

  const logMessage = async (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
    await speak(message);
  };

  const highlightLine = async (lineNumber) => {
    setHighlightedLine(lineNumber);
    await logMessage(`Line ${lineNumber + 1}: ${explanations[language][lineNumber + 1] || "Executing line " + (lineNumber + 1)}`);
  };

  const focusEndLine = async (lineNumber) => {
    setFocusedEndLine(lineNumber);
    let message = "End of the execution";
    await speak(message);
  };

  const customSleep = async () => {
    let start = Date.now();
    let ms = delayRef.current;
    while (Date.now() - start < ms) {
      if (isPausedRef.current) {
        await new Promise((resolve) => {
          let interval = setInterval(() => {
            if (!isPausedRef.current) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  const runStringConcatenationPython = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(1);
    setStringVariables({ str1: { variable_name: 'str1', value: str1 } });
    setHighlightedStringVariables(['str1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(2);
    setStringVariables((vars) => ({ ...vars, str2: { variable_name: 'str2', value: str2 } }));
    setHighlightedStringVariables(['str2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(3);
    let result_plus = '';
    setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
    setHighlightedStringVariables(['result_plus']);
    await customSleep();
    setHighlightedStringVariables([]);

    for (let i = 0; i < str1.length; i++) {
      await highlightLine(5);
      setHighlightedStringIndices([{ stringName: 'str1', index: i }]);
      let char = str1[i];
      setVariables((vars) => ({ ...vars, char: { variable_name: 'char', value: char } }));
      setHighlightedVariables(['char']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedVariables([]);

      await highlightLine(6);
      result_plus += char;
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringVariables([]);
    }

    for (let i = 0; i < str2.length; i++) {
      await highlightLine(8);
      setHighlightedStringIndices([{ stringName: 'str2', index: i }]);
      let char = str2[i];
      setVariables((vars) => ({ ...vars, char: { variable_name: 'char', value: char } }));
      setHighlightedVariables(['char']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedVariables([]);

      await highlightLine(9);
      result_plus += char;
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringVariables([]);
    }

    await highlightLine(11);
    await logMessage(result_plus);
    await customSleep();

    await focusEndLine(11);
    setIsRunning(false);
  };

  const runStringConcatenationJava = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(2);
    setStringVariables({ str1: { variable_name: 'str1', value: str1 } });
    setHighlightedStringVariables(['str1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(3);
    setStringVariables((vars) => ({ ...vars, str2: { variable_name: 'str2', value: str2 } }));
    setHighlightedStringVariables(['str2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(4);
    let result_plus = '';
    setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
    setHighlightedStringVariables(['result_plus']);
    await customSleep();
    setHighlightedStringVariables([]);

    for (let i = 0; i < str1.length; i++) {
      await highlightLine(6);
      setHighlightedStringIndices([{ stringName: 'str1', index: i }]);
      let c = str1[i];
      setVariables((vars) => ({ ...vars, c: { variable_name: 'c', value: c } }));
      setHighlightedVariables(['c']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedVariables([]);

      await highlightLine(7);
      result_plus += c;
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringVariables([]);
    }

    for (let i = 0; i < str2.length; i++) {
      await highlightLine(10);
      setHighlightedStringIndices([{ stringName: 'str2', index: i }]);
      let c = str2[i];
      setVariables((vars) => ({ ...vars, c: { variable_name: 'c', value: c } }));
      setHighlightedVariables(['char']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedVariables([]);

      await highlightLine(11);
      result_plus += c;
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringVariables([]);
    }

    await highlightLine(14);
    await logMessage(result_plus);
    await customSleep();

    await focusEndLine(16);
    setIsRunning(false);
  };

  const runStringConcatenationC = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(3);
    setStringVariables({ str1: { variable_name: 'str1', value: str1 } });
    setHighlightedStringVariables(['str1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(4);
    setStringVariables((vars) => ({ ...vars, str2: { variable_name: 'str2', value: str2 } }));
    setHighlightedStringVariables(['str2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(5);
    let result_plus = '';
    setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
    setHighlightedStringVariables(['result_plus']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(7);
    let currentIndex = 0;
    setVariables((vars) => ({ ...vars, currentIndex: { variable_name: 'currentIndex', value: currentIndex } }));
    setHighlightedVariables(['currentIndex']);
    await customSleep();
    setHighlightedVariables([]);

    for (let i = 0; i < str1.length; i++) {
      await highlightLine(9);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(10);
      setHighlightedStringIndices([{ stringName: 'str1', index: i, iteratorName: 'i' }]);
      result_plus += str1[i];
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedStringVariables([]);

      await highlightLine(11);
      currentIndex += 1;
      setVariables((vars) => ({ ...vars, currentIndex: { variable_name: 'currentIndex', value: currentIndex } }));
      setHighlightedVariables(['currentIndex']);
      await customSleep();
      setHighlightedVariables([]);
    }

    for (let i = 0; i < str2.length; i++) {
      await highlightLine(14);
      setVariables((vars) => ({ ...vars, i: { variable_name: 'i', value: i } }));
      setHighlightedVariables(['i']);
      await customSleep();
      setHighlightedVariables([]);

      await highlightLine(15);
      setHighlightedStringIndices([{ stringName: 'str2', index: i, iteratorName: 'i' }]);
      result_plus += str2[i];
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedStringVariables([]);

      await highlightLine(16);
      currentIndex += 1;
      setVariables((vars) => ({ ...vars, currentIndex: { variable_name: 'currentIndex', value: currentIndex } }));
      setHighlightedVariables(['currentIndex']);
      await customSleep();
      setHighlightedVariables([]);
    }

    await highlightLine(19);
    await logMessage(result_plus);
    await customSleep();

    await highlightLine(20);
    await customSleep();

    await focusEndLine(21);
    setIsRunning(false);
  };

  const runStringConcatenationCpp = async () => {
    resetState();
    setIsRunning(true);
    setIsPaused(false);

    await highlightLine(5);
    setStringVariables({ str1: { variable_name: 'str1', value: str1 } });
    setHighlightedStringVariables(['str1']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(6);
    setStringVariables((vars) => ({ ...vars, str2: { variable_name: 'str2', value: str2 } }));
    setHighlightedStringVariables(['str2']);
    await customSleep();
    setHighlightedStringVariables([]);

    await highlightLine(7);
    let result_plus = '';
    setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
    setHighlightedStringVariables(['result_plus']);
    await customSleep();
    setHighlightedStringVariables([]);

    for (let i = 0; i < str1.length; i++) {
      await highlightLine(9);
      setHighlightedStringIndices([{ stringName: 'str1', index: i }]);
      let c = str1[i];
      setVariables((vars) => ({ ...vars, c: { variable_name: 'c', value: c } }));
      setHighlightedVariables(['char']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedVariables([]);

      await highlightLine(10);
      result_plus += c;
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringVariables([]);
    }

    for (let i = 0; i < str2.length; i++) {
      await highlightLine(13);
      setHighlightedStringIndices([{ stringName: 'str2', index: i }]);
      let c = str2[i];
      setVariables((vars) => ({ ...vars, c: { variable_name: 'c', value: c } }));
      setHighlightedVariables(['c']);
      await customSleep();
      setHighlightedStringIndices([]);
      setHighlightedVariables([]);

      await highlightLine(14);
      result_plus += c;
      setStringVariables((vars) => ({ ...vars, result_plus: { variable_name: 'result_plus', value: result_plus } }));
      setHighlightedStringVariables(['result_plus']);
      await customSleep();
      setHighlightedStringVariables([]);
    }

    await highlightLine(17);
    await logMessage(result_plus);
    await customSleep();

    await highlightLine(18);
    await customSleep();

    await focusEndLine(19);
    setIsRunning(false);
  };

  const runStringConcatenation = async () => {
    if (language === 'Python') {
      await runStringConcatenationPython();
    } else if (language === 'Java') {
      await runStringConcatenationJava();
    } else if (language === 'C') {
      await runStringConcatenationC();
    } else if (language === 'C++') {
      await runStringConcatenationCpp();
    }
  };

  return {
    highlightedLine,
    focusedEndLine,
    isRunning,
    isPaused,
    delay,
    logs,
    variables,
    arrayVariables,
    stringVariables,
    highlightedVariables,
    highlightedStringVariables,
    highlightedStringIndices,
    isMuted,
    codeSamples,
    speak,
    setIsMuted: setLocalIsMuted,
    updateVariables,
    setHighlightedLine,
    setFocusedEndLine,
    setIsRunning,
    setIsPaused,
    setDelay,
    setLogs,
    setVariables,
    setArrayVariables,
    setStringVariables,
    setHighlightedVariables,
    setHighlightedStringVariables,
    setHighlightedStringIndices,
    runStringConcatenation
  };
};
